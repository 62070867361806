.logo-stage {
  margin-top: 200px;
  margin-bottom: 50px;
  @media screen and (max-width: $screen-md) {
    margin-top: 4em;
    @media screen and (max-width: $screen-xs) {
      margin-top: 60px;
    }
  }
}

.logo-stage__image {
  display: block;
  width: 45px;
  height: 45px;
  background: url('../../../static/images/exporo_icon.svg');
  background-size: cover;
  position: absolute;
  left: 50%;
  margin-left: -22px;
}
