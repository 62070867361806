.kpis {
  padding: 75px 0;
  @media screen and (max-width: $screen-xs-max){
    padding: 55px 0;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
    @media screen and (max-width: $screen-md) {
      display: block;
    }
  }
  &__background-blue {
    background: $background2;
    color: white;
    .kpis__headline>*, 
    .kpis__subline {
      color: white;
    }
  }
  &__background-grey {
    background: $background;
    color: $color-grey-base;
    .kpis__item {
      .inner {
        i {
          &:before {
            color: $light-blue;
          }
        }
      }
    }
    .kpis__value--text, 
    .kpis__desc {
      color: $color-grey-base;
    }
  }
  &__background-grey, 
  &__background-blue {
    &.not-fullwidth {
      &:before {
        content: '';
        position: absolute;
        left: -1500px;
        top: 0;
        bottom: 0;
        right: 50%;
        background: $background;
        @media screen and (max-width: $screen-md) {
          left: 0;
          background: transparent;
        }
      }
    }
  }
  &__background-blue {
    &.not-fullwidth {
      &:before {
        background: $background2;
        @media screen and (max-width: $screen-md) {
          background: transparent;
        }
      }
    }
  }
  &__background-white {
    background: white;
    color: $color-grey-base;
    .kpis__item {
      .inner {
        i {
          &:before {
            color: $light-blue;
          }
        }
      }
    }
    .kpis__value--text, 
    .kpis__desc {
      color: $color-grey-base;
    }
  }
  &__headline {
    @extend .headline;
  }
  &__subline {
    @extend .subline;
    font-size: 21px;
    line-height: 50px;
  }
}

.kpis__item {
  flex: 1;
  min-width: 25%;
  text-align: center;
  padding-right: 40px;
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 1em;
    padding-right: 0;
  }
  .inner {
    position: relative;
    padding: 0;
    display: inline-block;
    text-align: left;
    i {
      display: block;
      text-align: center;
      &:before {
        display: block;
        margin-bottom: 15px;
      }
      &.kpis__icon-size {
        &-normal {
          &:before {
            font-size: 60px;
          }
        }
        &-large {
          &:before {
            font-size: 120px;
            margin-bottom: 15px;
          }
        }
      }
      @media screen and (max-width: $screen-xs-max) {
        display: block;
        margin-right: 0;
        &:before {
          font-size: 3.5em !important;
        }
      }
    }
  }
}
.kpis__items_alignment-baseline {
  .kpis__item {
    align-self: baseline;
  }
}

.kpis__value {
  display: inline-block;
  font-weight: 300;
  span {
    display: block;
    margin-top: 0;
    text-align: center;
    p {
      margin: 0 0;
    }
  }
}
.kpis__value--text {
  font-size: 21px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
  line-height: 50px;
  text-transform: uppercase;
  @media screen and (max-width: $screen-xs-max) {
    word-break: break-word;
  }
}

.kpis__desc {
  font-size: 16px;
  padding-bottom: 20px;
  p {
    line-height: 26px;
  }
  @media print {
    font-size: 12px;
  }
  a {
    color: $color-grey-base;
    &:hover {
      text-decoration: underline;
    }
  }
}