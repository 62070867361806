.content-block {
  padding: 75px 0 50px;
  position: relative;
  @media screen and (max-width: $screen-xs-max){
    padding: 55px 0 35px;
    &--iscentered{
      padding: 55px 0 35px;
    }
  }
  .wrap{
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    @media screen and (max-width: $screen-xs-max) {
      padding:0 25px;
    }
    .home &{
      padding: 0 25px;
    }
  }
  &.dtp-margins{
    .wrap{
      padding: 0 25px;
      .file_info{
        padding-left: 0;
      }
    }
  }

  &__image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__subline {
    @extend .subline;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin:0;
    margin-top: 10px;
    @media screen and (max-width: $screen-md) {
      font-size: 20px;
      max-width: 100%;
    }
  }

  &__headline {
    max-width: 100%;
    @media screen and (max-width: $screen-xs-max) {
      word-break: break-word;
    }
    &>* {
      @extend .headline;
      .firstBlock & {
        font-size: 60px;
        font-weight: 400;
        line-height: 75px;
        margin: 0;
        margin-bottom: 15px;
        position: relative;
        text-align: left;
        @media screen and (max-width: $screen-md) {
          font-size: 30px;
          line-height: 50px;
          max-width: 100%;
          word-break: break-word;
        }
        @media screen and (max-width: $screen-xs) {
          font-size: 10vw;
          line-height: 50px;
          max-width: 100%;
          word-break: break-word;
        }
      }
    }
  }

  &__text {
    margin: 30px auto 0;
    position: relative;
    br{
      display:none;
    }
    .raw-html{
      br{
        display:block;
      }
      a[href^="tel:"],
      a[href^="mailto:"]{
        @media screen and (max-width: $screen-xs-max) {
          max-width: 80%;
          margin: 5px auto;
        }
      }
    }
    &.columns_one {
      width: 100%;
      @media print {
        columns: 2;
        column-gap: 60px;
      }
      ol,
      ul{
        li{
          display: block;
          padding-right: 36px;
          width: 100%;
          vertical-align: top;
          @media screen and (max-width: $screen-xs-max) {
            width:100%;
          }
        }
      }
    }
    img {
      max-width: 100%;
    }
    &.columns_two {
      columns: 2;
      column-gap: 60px;
    }
    &.columns_three {
      columns: 3;
      column-gap: 30px;
    }
    @media screen and (max-width: 1000px) {
      columns: 1 !important;
      max-width: 100%;
    }
    p {
      line-height: 30px;
      margin-bottom: 1em;
      margin-top: 0;
      &:empty,
      & br:only-child {
        @media screen and (max-width: $screen-xs-max) {
          display: none;
        }
      }
      + h1, 
      + h2, 
      + h3, 
      + h4, 
      + h5, 
      + h6 {
        margin-top: 70px;
      }
    }
    a {
      font-weight: 600;
      color: $brand-secondary;
      &.btn{
        color:white;
        margin-bottom: 30px;
      }
    }
    h1, 
    h2 {
      text-align: left;
    }
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6 {
      line-height: 34px;
      font-size: 24px;
      margin: 0;
      margin-bottom: 18px;
    }
    table {
      table-layout: fixed;
      margin: 0;
      width: 100%;
      tr {
        line-height: 1.4;
        @media screen and (max-width: $screen-xs-max) {
          line-height: 1.8;
        }
      }
      th {
        background: $background;
        font-weight: bold;
        padding: 5px;
        text-transform: uppercase;
        &:first-child {
          text-align: left;
        }
      }
      th ~ th, 
      td ~ td {
        text-align: right;
      }
      thead {
        border-width: 1px 0 1px 0;
        border-color: #000;
        border-style: solid;
      }
      tbody {
        tr {
          background: #FFFFFF;
          border-width: 0;
          border-color: #000000;
          border-style: solid;
          line-height: 2;
          &:nth-child(even) {
            background: $background;
          }
          &:first-child {
            border-top-width: 2px;
          }
          &:last-child {
            border-bottom-width: 2px;
          }
        }
        td {
          padding: 0 5px;
          position: relative;
        }
      }
    }
    hr {
      display: none !important;
    }
  }
  ol,
  ul {
    padding: 0 10px 0 15px;
    margin: 30px 0;
    li {
      align-items: center;
      font-size: 16px;
      margin-left: 0;
      list-style: none;
      margin-bottom: 40px;
      padding-left: 10px;
      position: relative;
      @media screen and (max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
      &:before {
        content: '\25CF';
        color: $gray-solid-2;
        font-size: 20px;
        display: inline-block;
        left: -15px;
        margin-top: -3px;
        padding-right: 20px;
        position: absolute;
      }
    }
  }
  ol{
    counter-reset: counter-number;
    li{
      &:before{
        content: counter(counter-number);
        counter-increment: counter-number;
      }
    }
  }

  @media (max-width: $screen-md) {
    table {
      thead {
        display: none;
      }
      tbody {
        tr {
          background: #FFFFFF;
          border-width: 0 0 1px 0;
          border-color: #000000;
          border-style: solid;
          &:nth-child(even) {
            background: $background;
          }
          &:first-child {
            border-top-width: 2px;
          }
          &:last-child {
            border-bottom-width: 2px;
          }
        }
        td {
          display: block;
          width: 100%;
          padding: 0 5px;
          padding-left: 50%;
          position: relative;
          text-align: right;
        }
        td:before {
          content: attr(data-line);
          position: absolute;
          left: 5px;
        }
      }
    }
  }
  ~ .content-block{
    .raw-html{
      display: block;
      margin-top: -30px;
      a[href^="tel:"],
      a[href^="mailto:"]{
        margin: 0;
        @media screen and (max-width: $screen-xs-max) {
          max-width: max-content;
          margin: 5px auto;
        }
      }
      table{
        td{
          padding-left: 1em;
        }
      }
    }
  }
}

a.content-block__file {
  display: block;
  color: $brand-primary;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  transition: all 0.2s ease-out;
  &:hover {
    .file_info, 
    .file_download {
      text-decoration: none;
      background: $brand-primary;
      color: #fff;
      i:before {
        color: #fff;
      }
    }
  }
  .file_info {
    padding: 10px;
    transition: all 0.2s ease-out;
    i {
      font-size: 40px;
      line-height: 1;
      color: $brand-primary;
      &:before {
        color: $brand-primary;
      }
    }
    span {
      display: inline-block;
      font-size: 15px;
      vertical-align: super;
      word-break: break-all;
      @media screen and (max-width: $screen-xs-max) {
        max-width: 70%;
        vertical-align: top;
        padding-top: 5px;
      }
    }
  }
  .file_download {
    display: block;
    border: 1px solid $brand-primary;
    padding: 5px 10px;
    margin-top: 3px;
    letter-spacing: 1px;
    transition: all 0.2s ease-out;
  }
}

.video-container {
  text-align: center;
  @media screen and (max-width: $screen-xs-max) {
    iframe {
      width: calc(100vw - 50px) !important;
      height: 56.25vw !important;
    }
  }
}

.content-block--iscentered {
  .wrap {
    align-items: center;
  }
  .content-block__headline > * {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .content-block__headline,
  .content-block__text {
    h2, h3, h4, h5, h6 {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  .content-block__subline {
    text-align: center;
  }
  .content-block__text {
    text-align: center;
  }
}