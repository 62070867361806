.btn,
.auth-btn {
  display: inline-block;
  background: $brand-secondary;
  position: relative;
  border-radius: 50px;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 10px;
  padding: 16px 30px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  @media screen and (max-width: $screen-xs-max) {
    line-height: 20px;
    max-width: max-content;
    padding: 10px 30px;
  }
  @media print {
    display: none;
  }
  &:hover {
    color: white;
    opacity: 0.8;
    text-decoration: none;
  }
  &:focus, 
  &.active {
    transform: scale(0.95);
    color: white;
    outline: none;
  }
}

a.btn {
  text-transform: uppercase;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
  &:hover, 
  &:focus {
    text-decoration: none;
  }
}

.btn--small {
  @extend .btn;
  background: $brand-secondary;
  border: 1px solid $brand-secondary;
  color: white;
  font-size: 9px;
  padding: 7px 15px;
  &:hover {
    color: white;
    border: 1px solid white;
  }
}

a[href^="tel:"], 
a[href^="mailto:"] {
  background: $brand-secondary;
  border: 1px solid $brand-secondary;
  border-radius: 50px;
  color: white !important;
  display: inline-block;
  margin-right: 30px;
  margin-top: 60px;
  font-size: 12px;
  font-weight: 400 !important;
  letter-spacing: 1px;
  line-height: 10px;
  padding: 16px 30px 16px 45px;
  position: relative;
  text-decoration: none !important;
  transition: all 0.5s ease-out;
  @media screen and (max-width: $screen-xs-max) {
    display: block;
    line-height: 20px;
    margin: 30px 0 30px;
  }
  &:before {
    position: absolute;
    left: 5px;
    top: 13px;
    transition: all 0.5s ease-out;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    box-shadow: none;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 2em;
    margin-right: 0;
    text-align: center;
    line-height: 1em;
    margin-left: .5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e812';
    text-shadow: none;
    @media screen and (max-width: $screen-xs-max) {
      top: calc(54px - 70%);
    }
  }
  &:hover {
    color: white;
    opacity: 0.8;
    &:before {
      color: white;
    }
  }
  &:focus, 
  &.active {
    transform: scale(0.95);
  }
  @media print {
    display: none;
  }
}

a[href^="mailto:"] {
  background: white;
  border-color: $gray-middle;
  color: $gray-middle !important;
  &:before {
    content: "\e813";
    color: $gray-middle;
  }
  &:hover {
    color: $gray-middle;
    &:before {
      color: $gray-middle;
    }
  }
}
a, 
button {
  &:focus, 
  &:active {
    outline: none;
  }
}
