.video{
  padding: 75px 0;
  position: relative;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }
  .wistia_responsive_wrapper,
  .video__container{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }
  .video__iframe{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.centered{
    background: $light-blue;
    overflow: hidden;
    &:before{
      content: '';
      background: white;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 450px;
      left: 0;
      right: 0;
      @media screen and (max-width: $screen-xs-max){
        display: none;
      }
    }
    .video__content{
      padding: 0 20%;
      margin-top: 3em;
      @media screen and (max-width: $screen-xs-max){
        padding: 0;
      }
    }
    &.bestand{
      background: lighten($bestand, 45%);
    }
    &.finanzierung{
      background: lighten($finanzierung, 45%);
    }
  }

  &.fullwidth{
    background: black;
    height: 100%;
    overflow: hidden;
    padding: 0;
    @media screen and (max-width: $screen-xs-max){
      padding: 0;
    }
    .video__text{
      display: none;
    }
    #video{
      margin: 0 auto;
      left: 17%;
      display:none;
      position: fixed;
      top: 20%;
      width: 70%;
      z-index: 1000;
      &:target,
      &:target + #mask{
        display: block;
      }
    }
    .close{
      display: block;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 1%;
      right: 1%;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-decoration: none;
      text-align: center;
      color: white;
      font-weight: 700;
      border: 1px solid white;
      border-radius: 100%;
      z-index: 1;
      &:before{
        font-family: fontello;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        box-shadow: none;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        line-height: 30px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: none;
        content: '\e81e';
        transition: all 0.15s;
        vertical-align: super;
      }
    }
    .video__image{
      background-attachment: fixed !important;
      background-color: black !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 500px;
      opacity: 0.7;
      position: relative;
      @media screen and (max-width: 1024px){
        height: 450px;
        background-attachment: scroll !important;
      }
      @media screen and (max-width: $screen-sm-max){
      height: 350px;
    }
      @media screen and (max-width: $screen-xs-max){
        height: 250px;
      }
    }
    .video__btn{
      width: 100%;
      text-align: center;
      top: 45%;
      position: absolute;
      z-index: 2;
      a{
        &:hover{
          text-decoration: none;
        }
      }
    }
    #open-overlay{
      color: white;
      display: inline-block;
      &:before{
        font-family: fontello;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        box-shadow: none;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 2em;
        text-align: center;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: none;
        content: '\e801';
        border: 1px solid white;
        border-radius: 100%;
        padding: 0.4em 0.3em 0.4em 0.4em;
        margin-right: 5px;
      }
    }
  }

  &.left{
    background: $light-blue;
    &:after{
      content:'';
      clear: both;
      display: table;
    }
    .video__text{
      float: right;
      padding-left: 1em;
      width: 45%;
      @media screen and (max-width: $screen-xs-max){
        padding-left:0;
        padding-top: 1em;
        width: 100%;
      }
    }
    .video__content{
      float: left;
      width: 55%;
      @media screen and (max-width: $screen-xs-max){
        width: 100%;
      }
    }
    &.bestand{
      background: lighten($bestand, 45%);
    }
    &.finanzierung{
      background: lighten($finanzierung, 45%);
    }
  }
}

#mask{
  background:rgba(0,0,0,0.5);
  display:none;
  left:0;
  height:100%;
  position:fixed;
  top:0;
  transition: all 0.4s;
  width:100%;
  z-index:500;
}
