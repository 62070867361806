.usp {
  padding: 25px 0 20px 0;
  .wrap {
    display: flex;
    @media screen and (max-width: $screen-md) {
      display: block;
    }
  }
  &__background-blue {
    background: $background2;
    color: white;
  }
  &__background-grey {
    background: $background3;
    color: $black;
  }
  &__background-white {
    background: white;
    color: $black;
  }

  &__item {
    flex: 2;
    text-align: center;
    &:last-child {
      align-self: flex-end;
      flex: 1;
      @media print {
        display: none;
      }
    }
    .btn {
      display: inline-block;
      @media screen and (max-width: $screen-md) {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
    li {
      list-style: none;
      padding: 0 1em 1em 1em;
    }
  }
}

.container-block {
  + .usp {
    margin-top: 50px;
  }
}

.benefits {
  + .usp {
    margin-top: 0 !important;
  }
}
