.pagination, 
.pagination-sm {
  padding: 1em;
  margin: 0;
  text-align: center;
  li {
    display: inline-block;
    list-style: none;
    padding: 0.5em;
    background: $background;
    color: $gray-base;
    &.active {
      font-weight: bold;
    }
    &.disabled {
      color: darken($gray-solid, 10%);
    }
    a {
      color: $color-grey-base;
      transition: all 0.4s;
      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }
}
