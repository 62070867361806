.breadcrumb {
  .firstStage & {
    margin-top: 0;
    .container {
      margin-top: 200px;
      position: absolute;
      width: 100%;
      z-index: 100;
      @media screen and (max-width: $screen-xs) {
        margin-top: 70px;
      }
      .breadcrumb__link--active,
      .breadcrumb__link--mobile-back{
        color: white !important;
        &:before {
          color: white;
        }
      }
      a {
        color: white;
      }
    }

  }
  .container {
    text-align: left;
    width: 100%;
    .breadcrumb__link--active,
    a {
      color: $color-grey-base;
      font-size: 16px;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
    }
    .breadcrumb__link--active {
      color: $brand-secondary;
      font-weight: bold;
      @media screen and (max-width: $screen-xs) {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        white-space: unset;
        max-height: 70px;
        color: $light-blue;
        &:before {
          color: $light-blue;
        }
      }
    }
    &:empty {
      display: none;
    }
  }
  &__link {
    display: inline-block;
    position: relative;
    ~ .breadcrumb__link {
      padding-left: 2em;
      &:before {
        content: '\0003C';
        font-size: 20px;
        font-weight: 700;
        margin-left: -1.2em;
        margin-top: -2px;
        position: absolute;
      }
    }
    &--mobile-back,
    a {
      transition: all 0.2s;
      &:hover {
        text-decoration: none;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          border-bottom: 1px solid $color-grey-base;
          .firstStage & {
            border-color: white;
          }
        }
      }
    }
    &--mobile-back {
      color: $light-blue !important;
      display: inline-block !important;
      height: 100%;
      vertical-align: top;
      @media screen and (min-width: $screen-xs-max) {
        display: none !important;
      }
    }
    @media screen and (max-width: $screen-xs) {
      display: none;
      &--active {
        display: block;
        max-width: 80% !important;
        padding-left: 0 !important;
        &:before {
          content: "" !important;
        }
      }
    }
  }
}