$navi-height: 50px;

body {
  .header-nav {
    position: relative;
    /*@media screen and (max-width: $screen-md-min) {
      opacity: 0.99;
      z-index: 0;
    }*/
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .exporo-not-scrollable {
    header {
      display: none;
    }
  }
}

header {
  top: 0;
  position: fixed;
  color: $black;
  line-height: 20px;
  width: 100%;
  transition: 0.5s ease;
  z-index: 999999;

  @media screen and (min-width: $screen-md-min) {
    padding: 0 0 30px 0;
    position: fixed;
  }
  /*@media screen and (max-width: $screen-md-min){
    .header-toggle-container,
    nav{
      position: fixed;
    }
  }*/
  .text-right {
    color: #fff;
  }
  &.collapse {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    a {
      color: $color-grey-base;
      .firstStage & {
        color: $color-grey-base;
        &:hover {
          color: $brand-secondary;
        }
      }
    }
    .auth-btn {
      color: $color-grey-base;
      border-color: $color-grey-base;
    }
    /*@media screen and (max-width: $screen-md-min){
      background: transparent;
      box-shadow: unset;
    }*/
  }
  .firstStage & {
    color: white;
  }
  @media screen and (min-width: $screen-md-min) {
    line-height: 50px;
    &.collapse {
      line-height: 1;
      color: $color-grey-base;
      padding-bottom: 0;
    }
  }
}

.header-phone {
  @extend .text-size-small;
  padding: 5px 7px;
  text-align: right;
  display: none;
  transition: 0.4s;
  text-transform: uppercase;
  margin-top: 0;

  display: block;
  .collapse & {
    margin-top: 0;
    padding: 0;
    display: none;
  }
  &>div {
    .collapse & {
      line-height: 0;
    }
  }
  .logo-container{
    margin-top: -5px;
    margin-right: 2em;
  }

  @media screen and (min-width: $screen-md-min) {
    display: block;
    .collapse & {
      margin-top: 0;
      padding: 0;
      display: none;
    }
    &>div {
      .collapse & {
        line-height: 0;
      }
    }
  }
  @media screen and (max-width: $screen-md-min) {
    display: none;
  }
}

.header-logo {
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  a {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: block;
    /*@media screen and (max-width: $screen-md-min) {
      display: none;
    }*/
  }
  position: absolute;
  left: 15px;
  display: inline-block;
  width: auto;
  @media screen and (min-width: $screen-md-min) {
    position: absolute;
    left: 15px;
    display: inline-block;
    width: auto;
  }
  /*@media screen and (max-width: $screen-md-min) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: -1;
  }*/
}

.header-logo--img {
  display: block;
  &.mobile{
    display: none;
    /*@media screen and (max-width: $screen-md-min) {
      display: inline-block;
      position: absolute;
      top:15px;
      right:-180px;
      left: auto;
      z-index:10;
    }*/
  }
  display: inline-block;
  height: 40px;
  @media screen and (min-width: $screen-md-min) {
    margin: -5px 80px 0 0;
    top: -19px;
    .collapse & {
      margin: 10px 80px 10px 0;
    }
  }
  /*@media screen and (min-width: $screen-md-min) {
    display: inline-block;
    height: 40px;
    margin: -5px 80px 0 0;
    top: -19px;
    .collapse & {
      margin: 10px 80px 10px 0;
    }
  }*/
  svg {
    display: block;
    height: 60px;
    line-height: 60px;
    width: 10em;
    .logo-text {
      fill: $black !important;
    }
    @media screen and (max-width: $screen-xs-max){
      width: 7em;
      /*.logo-text{
        display: none;
      }*/
    }
  }
}
.firstStage {
  svg {
    .logo-text {
      fill: white !important;
    }
  }
  .auth-btn {
    border: 1px solid white;
    color: white;
    &:hover {
      color: white;
    }
  }
}
.collapse {
  svg {
    height: auto;
    .logo-text {
      fill: $color-grey-base !important;
    }
  }
  .auth-btn {
    &:hover {
      color: $color-grey-base;
    }
  }
}

.plain-header{
  padding-top: 1em;
  svg{
    width: 20em;
  }
  .logo-text {
    fill: $black !important;
  }
}

.auth-btn,
.exporo-auth-button,
.exporo-auth-button-logout{
  background: transparent;
  color: $color-grey-base;
  display: inline-block;
  line-height: 1 !important;
  /*@media screen and (max-width: $screen-md-min) {
    display: block;
  }*/
}
.auth-btn {
  border: 1px solid $color-grey-base;
  border-radius: 50px;
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;
  transition: 0.2s;
  .login &{
    @media screen and (max-width: $screen-md-min) {
      padding: 7px;
    }
  }
  .cockpit &{
    @media screen and (max-width: $screen-md-min) {
      padding: 0 7px;
      font-size:22px;
      border-width: 0;
    }
  }
  .logout &{
    @media screen and (min-width: $screen-md-min) {
      padding: 10px 13px;
    }
  }
  &-text{
    .login &{
    }
    .cockpit &{
      @media screen and (max-width: $screen-md-min) {
        display: none;
      }
    }
    .logout &{
      display: none;
      @media screen and (max-width: $screen-md-min) {
        display: block;
      }
    }
  }
  .exporo-icon{
    &:before{
      font-family: fontello;
      font-style: normal;
      font-weight: 400;
      font-size: inherit;
      box-shadow: none;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      text-align: center;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: none;
      content: '\e830';
      border-radius: 100%;
      .cockpit &{
        content:"\f200";
      }
      .logout &{
        content:"\e82f";
        margin-right: -5px;
      }
    }
    ~ .auth-btn-text{
      margin-left: 7px;
    }
  }
  &:hover {
    color: $color-grey-base;
    opacity: 0.7;
  }
  @media screen and (max-width: $screen-md-min) {
    font-size: 9px;
    padding: 10px;
  }
}
.nav {
  display: inline;
  float: right;
  max-width: 75%;
  text-align: right;
  @media screen and (max-width: $screen-md-min) {
    max-width: 100%;
  }
  &--mobile{
    display: none !important;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
  }
  li{
    display: inline-block;
    padding-right: 20px;
    line-height: 50px;
    position: relative;
    transition: all 0.2s;
  }
  a {
    display: inline-block;
    font-size: 16px;
    color: $color-grey-base;
    position: relative;
    text-transform: uppercase;
    transition: all 0.2s;
    .collapse & {
      line-height: 60px;
    }
    .firstStage & {
      color: white;
    }

    /*@media screen and (min-width: $screen-md-min) {
      padding-right: 20px;
      line-height: 50px;
      &:last-of-type {
        padding: 0;
      }
      .collapse & {
        line-height: 60px;
      }
      .firstStage & {
        color: white;
      }
    }*/
    &:hover {
      color: $brand-secondary;
    }
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
    li:last-of-type {
      padding: 0;
    }
  }

  .nav-additional a:last-child {
    padding-right: 0;
  }
}
.nav__btn-container{
  @media screen and (max-width: $screen-md-min) {
    display: none !important;
    &.mobile{
      display: block !important;
      position: absolute;
      right: 15px;
      width: auto;
      top: 17px;
    }
  }
  @media screen and (min-width: $screen-md-min) {
    display: inline-block;
    .nav &{
      padding-right: 0;
    }
    &.mobile{
      display: none !important;
    }
  }
}
.nav-additional {
  float: right;
}
/*@media screen and (min-width: $screen-md-min) {
  .nav-additional {
    float: right;
  }
}

.nav .nav-home-mobile{
  display:none;
  @media screen and (max-width: $screen-md-min) {
    display: block;
  }
}*/
