.maps-to-png {
  margin: 0;
  padding: 0;
  .maps {
    width: 100%;
    height: 100%;
    padding: 0;
    .wrap {
      height: 100% !important;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }
  .marker {
    font-size: 14px;
  }
  .mapboxgl-control-container {
    display: none !important;
  }
}

.maps {
  padding: 75px 0;
  position: relative;
  @media screen and (max-width: $screen-xs) {
    padding: 55px 0;
  }
  #map {
    width: 100%;
    height: 100%;
    @media print {
      max-width: 7.5in;
    }
  }

  &_content-loupe {
    border-radius: 100%;
    border: 5px solid #fff;
    box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.3);
    display: none;
    height: 250px;
    right: 5em;
    overflow: hidden;
    position: absolute;
    top: 5em;
    transition: 0.3s ease-out;
    width: 250px;
    @media screen and (max-width: $screen-md) {
      height: 250px;
      width: 250px;
    }
    @media screen and (max-width: $screen-xs) {
      height: 200px;
      width: 200px;
      right: 2em;
      top: 2em;
    }
    &__content {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__container {
      position: absolute;
      width: auto;
      height: 30px;
      left: 0;
      right: 0;
      margin: auto;
      top: calc(50% - 35px);
      text-align: center;
      @media screen and (max-width: $screen-xs) {
        top: calc(50% - 30px);
      }
    }
    &__marker {
      background-color: $maps-marker;
      color: white;
      font-weight: bold;
      font-size: 15px;
      display: inline-block;
      height: 20px;
      line-height: 20px;
      text-transform: uppercase;
      width: auto;
      padding: 0 20px;
      @media screen and (max-width: $screen-xs) {
        font-size: 15px;
        height: 25px;
        line-height: 25px;
      }
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $maps-marker;
        content: '';
        height: 0;
        left: 50%;
        margin-left: -5px;
        position: absolute;
        top: 22px;
        width: 0;
        @media screen and (max-width: $screen-xs) {
          top: 25px;
        }
      }
    }
  }
  &__container {
    position: relative;
    &:before {
      content: '';
      background: $background2;
      display: block;
      height: 710px;
      position: absolute;
      width: 100%;
      max-width: 1080px;
      top: -30px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: $screen-xs) {
        display: none;
      }
    }
    &.bestand{
      &:before{
        background: lighten($bestand, 45%);
      }
      .marker,
      .maps_content-loupe__marker{
        background: $bestand;
      }
      .marker:after,
      .maps_content-loupe__marker:after{
        border-top-color: $bestand;
      }
    }
    &.finanzierung{
      &:before{
        background: lighten($finanzierung, 45%);
      }
      .marker,
      .maps_content-loupe__marker{
        background: $finanzierung;
      }
      .marker:after,
      .maps_content-loupe__marker:after{
        border-top-color: $finanzierung;
      }
    }
  }
}

.marker {
  background-size: cover;
  background-color: $maps-marker;
  cursor: pointer;
  color: white;
  font-size: 17px;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 20px;
  width: auto;
  @media screen and (max-width: $screen-xs) {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
  }
  @media print {
    font-size: 12px;
  }
  &:empty {
    display: none !important;
  }

  &:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $maps-marker;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 30px;
    width: 0;
    @media screen and (max-width: $screen-xs) {
      top: 24px;
    }
  }
}
