.legal {
  padding: 0;
  &__content {
    color: $black;
    border: 1px solid $black;
    border-radius: 3px;
    padding: 1em 5em;
    text-align: center;
    font-size: 14.5px;
    @media screen and (max-width: $screen-xs-max){
      padding: 1em;
    }
    b {
      display: block;
      text-transform: uppercase;
    }
  }
}
