.blog_categories{
  padding: 0 0 75px;
  position: relative;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }
  .box {
    @extend .btn;
    color: white;
    border-radius: 50px;
    position: absolute;
    height: 45px;
    line-height: 30px;
    padding: 8px 50px 16px 2em;
    text-transform: unset;
    overflow: hidden;
    z-index: 10;
    &:after {
      font-family: fontello;
      font-style: normal;
      font-size: 6px;
      font-weight: 400;
      box-shadow: none;
      speak: none;
      text-decoration: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: none;
      position: absolute;
      right: 16px;
      top: 19px;
      display: block;
      line-height: 1;
      text-align: center;
      -webkit-transition: all .35s;
      -o-transition: all .35s;
      transition: all .35s;
      pointer-events: none;
      content: "\e805";
    }
    &:hover {
      border-radius: 10px;
      height: auto;
      overflow: hidden;
      @media screen and (max-width: $screen-xs-max){
        max-height: 15em;
        overflow-y: scroll;
      }
    }
    &__label{
      display: block;
      text-transform: capitalize;
    }
    input{
      display: none;
      &:checked{
        ~ label{
          font-weight: bold;
        }
      }
    }
    label{
      cursor: pointer;
    }
  }
}