#blog-container  {
  width: 100%;
}

.blog-date {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  text-align: center;
  height: 0px;
  line-height: 0px;
}
