.infobar-content{
    position: relative;
    z-index: 50;
    @media screen and (min-width: $screen-sm) {
        ~ section{
            .wrap{
                padding-left: 25px;
                padding-right: 360px;
            }
        }
    }
    .wrap {
        @media screen and (max-width: $screen-xs-max) {
            padding: 0;
        }
    }
    .infobar {
        position: absolute;
        margin-top: -150px;
        right: 25px;
        left: auto;
        background-color: $body-bg;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        border: 1px solid $dropdown-border;
        width: 315px;
        @media screen and (max-width: $screen-xs-max) {
            margin-left: auto;
            margin-right: auto;
            position: relative;
            right: auto;
        }
        &__headline {
            @extend .headline;
            font-size: 28px;
            margin: 0;
            padding: 10px;
        }
        &__list {
            list-style: none;
            margin: 0;
            padding:0;
        }
        &__point {
            border-top: 1px solid $dropdown-border;
            line-height: 1;
            padding: 10px;
            &>span{
                display: block;
            }
        }
        &__description {
            color: lighten($color-grey-base, 25%);
            font-size: 10px;
            font-weight: bold;
        }
        &__value {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            margin-top: 20px;
            text-align: right;
        }

    }
}
