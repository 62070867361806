.related_articles{
  padding: 0 0 75px;
  @media screen and (max-width: $screen-xs-max){
    padding: 0 0 55px; 
  }
  .wrap{
    padding: 0 10%;
  }
  &__headline{
    color:$brand-secondary;
    font-weight: bold;
    line-height:30px;
    margin-bottom:20px;
  }
  ul{
    margin: 0;
    padding:0;
    li{
      background: $background;
      border-bottom: 1px solid $color-grey-base;
      border-top: 1px solid $color-grey-base;
      cursor: pointer;
      display: block;
      list-style: none;
      padding:20px;
      position: relative;
      ~ li{
        border-top: none;
      }
      &:after {
        content: "\2304";
        position: absolute;
        display: block;
        font-size: 35px;
        top:10px;
        right:20px;
        text-align: center;
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
        transform: rotate(-90deg);
      }
      a{
        color:$color-grey-base;
        line-height:30px;
        @media screen and (max-width: $screen-xs-max){
          display: inline-block;
          max-width: 90%;
        }
      }
      &:hover{
        &:after,
        a{
          color: $brand-secondary;
          text-decoration: none;
        }
      }
    }
  }
}