.default-text{
  &.module-margin{
    padding: 75px 0;
    @media screen and (max-width: $screen-xs-max) {
      padding: 55px 0;
    }
  }
  &__subline{
    font-size: 22px;
  }
  p{
    margin-bottom: 10px;
  }
  ol,
  ul {
    padding: 0 10px 0 0;
    margin: 30px 0;
    li {
      align-items: center;
      font-size: 16px;
      margin-left: 0;
      list-style: none;
      margin-bottom: 25px;
      padding-left: 5px;
      position: relative;
      &:before {
        content: '\e810';
        font-size: 17px;
        font-family: 'fontello';
        color: $brand-primary;
        speak: none;
        font-style: normal;
        font-weight: bold;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        padding-right: 10px;
      }
      p{
        display: inline-block;
        max-width: 80%;
        vertical-align: top;
        margin: 0;
      }
    }
  }
  img{
    max-width: 100%;
  }
  &.bestand{
    li{
      &:before{
        color: $bestand;
      }
    }
  }
  &.finanzierung{
    li{
      &:before{
        color: $finanzierung;
      }
    }
  }
  em{
    color: $gray-middle;
    font-size: 12px;
  }
}