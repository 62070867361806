.cta {
  color: white;
  padding: 75px 0;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }
  @media print {
    font-size: 1em;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .headline;
    color: white;
  }
  &__background-blue,
  &__background-grey{
    background: $light-blue;
    color: white;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }
    .btn{
      color: white;
      background: transparent;
      border: 1px solid white;
    }
  }
  &__background-blue{
    background: $background2;
  }
  &__background-white{
    background: white;
    color: $color-grey-base;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-grey-base;
    }
  }
  p {
    margin: 5px 0;
    a {
      border: none;
      display: inline;
      color: white;
      float: none;
      font-size: inherit;
      letter-spacing: initial;
      padding: 1px;
      text-transform: inherit;
    }
  }
  &__btn {
    display: block;
    float: right;
    position: relative;
    padding-right: 3em;
    margin-top: 10px;
    &:after {
      content: "\e805";
      font-family: fontello;
      font-style: normal;
      font-weight: 400;
      box-shadow: none;
      speak: none;
      text-decoration: inherit;
      text-align: center;
      margin-left: .5em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: none;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      font-size: 0.5em;
      line-height: 1;
      padding: 2em 0.5em 0.5em;
      height: 100%;
      -webkit-transition: all .35s;
      -o-transition: all .35s;
      transition: all .35s;
      font-style: normal;
      font-variant: normal;
      -webkit-font-smoothing: antialiased;
      transform: rotate(-90deg);
    }
  }
}
