.products {
  background: $background;
  padding: 75px 0;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }
  .wrap{
    &.masonry-grid{
      margin-top:30px;
      padding: 0 5%;
      @media screen and (max-width: $screen-xs-max) {
        padding: 0 25px;
      }
    }
  }
  &:before{
    content: '';
    background: white;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 550px;
    left: 0;
    right: 0;
  }
  .teaser{
    background: white;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    border-radius: 5px;
    color:$color-grey-base;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    transition: all 0.4s;
    a{
      color: $color-grey-base;
      text-decoration: none;
    }
    &--image{
      text-align: center;
      height:130px;
      img{
        border-radius: 100%;
        margin-top: 30px;
        height: 130px;
        width: 130px;
      }
    }
    &--container{
      padding-top: 40px;
      &__headline{
        font-size:27px;
      }
      p{
        font-size: 16px;
        margin:0;
        padding:0.4em 1.5em 1em;
        @media screen and (min-width: $screen-xs-max) {
          height: 8em;
        }
      }
      ul{
        margin:0;
        padding:0;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        li{
          font-size:14px;
          font-weight: bold;
          border-bottom: 1px solid $gray-bright;
          text-align: left;
          padding-left: 5em;
          padding-right: 0.5em;
          height: 5em;
          display: flex;
          align-items: center;
          position: relative;
          &:first-child{
            border-top: 1px solid $gray-bright;
          }
          &:last-child{
            border-bottom:0;
          }
          &:before{
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            box-shadow: none;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            margin-right: 0;
            text-align: center;
            margin-left: .5em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\e810';
            text-shadow: none;
            position: absolute;
            left: 10px;
            top: 27%;
          }
          @media screen and (max-width: $screen-xs-max) {
            height: auto;
            padding: 0.7em 1em 0.7em 5em;
          }
        }
      }
    }
    &--content{
      padding: 25px 15px;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
    }
    &--link{
      position: absolute;
      bottom: 25px;
      width:100%;
      @media screen and (max-width: $screen-xs-max) {
        bottom: 0;
        margin: 20px 0;
        position: relative;
      }
      .btn{
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        font-weight: bold;
        padding: 20px 80px;
        @media screen and (max-width: $screen-xs-max) {
          padding: 13px 50px;
          width: 90%;
        }
      }
    }

  }
}

.product_finanzierung{
  .teaser--image{
    background: lighten($finanzierung, 45%);
  }
  .teaser--link .btn{
    background: $finanzierung;
  }
  .teaser--container__headline,
  li:before{
    color: $finanzierung;
  }
  .teaser--link .btn{
    border-color: $finanzierung;
  }
}

.product_bestand{
  .teaser--image{
    background: lighten($bestand, 45%);
  }
  .teaser--link .btn{
    background: $bestand;
  }
  .teaser--container__headline,
  li:before{
    color: $bestand;
  }
  .teaser--link .btn{
    border-color: $bestand;
  }
}
