.page--kpis {
  background-color: black;
  text-align: center;
  &__item {
    display: inline-block;
    margin: 20px 50px;
  }
  &__value,
  &__key {
    color: white;
  }

  &__value {
    font-size: 44px;
    line-height: 1;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
  }
}