.glossar-submenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 60px;
  text-align: center;
  &__left,
  &__right{
    display: none;
  }
  @media screen and (max-width: $screen-xs-max){
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: unset;
    justify-content: unset;
    &__container{
      position: relative;
    }
    &__left,
    &__right{
      background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      width: 50px;
    }
    &__right{
      background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
      left: auto;
      right: 0;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
}

.glossar-submenu--link {
  align-items: center;
  border-radius: 100%;
  border: 1px solid $color-grey-base;
  color: $color-grey-base;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  height: 60px;
  justify-content: center;
  margin: 0 10px 20px;
  transition: all 0.5s ease-out;
  width: 60px;
  &:hover, 
  &.active-filter {
    background: $brand-secondary;
    color: #fff;
    text-decoration: none;
    outline: none;
  }
  &:empty {
    display: none;
  }
  @media screen and (max-width: $screen-xs-max) {
    flex: 0 0 45px;
    height: 45px;
    width: 45px;
    margin: 0 7px 20px;
    &:first-child{
      margin-left: 25px;
      &:empty{
        + .glossar-submenu--link{
          margin-left: 25px;
        }
      }
    }
  }
}

.glossar {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 130px;
    left: 0;
    right: 0;
  }
  .teaser {
    background: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: $color-grey-base;
    height: 100%;
    width: 100%;
    padding: 25px 15px;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    &:hover {
      background: $brand-secondary;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
      color: white;
      .teaser--headline, 
      .teaser--summary, 
      .teaser--link .btn--small {
        color: white;
      }
      .teaser--link .btn--small {
        border-color: white;
      }
    }
    a {
      text-decoration: none;
    }
    &--headline {
      margin: 0;
      font-size: 20px;
      line-height: 26px;
      text-align: left;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 5vw;
      }
    }
    &--summary {
      color: $color-grey-base;
      margin-top: 10px;
      padding-right: 21px;
      max-height: 4.3em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      p {
        margin: 0;
      }
    }
    &--link {
      position: absolute;
      bottom: 25px;
    }
  }
}