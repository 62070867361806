body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  color: $color-grey-base;
}

.text-size-small {
  font-size: 12px;
}

.headline, 
.subline {
  color: $color-grey-base;
  font-family: 'Oswald', sans-serif;
  font-size: 44px;
  line-height: 56px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
  @media screen and (max-width: $screen-md) {
    font-size: 30px;
    line-height: 50px;
    max-width: 100%;
    word-break: break-word;
  }
  @media screen and (max-width: $screen-xs) {
    font-size: 9vw;
    line-height: 40px;
    letter-spacing: -1px;
    max-width: 100%;
    word-break: break-word;
  }
}
.subline {
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  line-height: 50px;
  text-transform: none;
}

h1, h2, h3, h4, h5, h6 {
  @extend .headline;
  hyphens: auto;
  &.space-top {
    margin-top: 140px;
  }
}

.text-center {
  text-align: center;
}

blockquote, 
cite {
  color: $color-grey-base;
  padding-left: 25px;
  margin-left: 0;
  font-style: italic;
  position: relative;
  &:before {
    content: '\e61d';
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    opacity: 0.4;
    font-size: 1em;
    position: absolute;
    left: 0;
    top: 0;
  }
}

a {
  color: $brand-secondary;
}

p {
  &:empty {
    margin: 0;
  }
}


.scroll-text {
  transform: translateY(-35px);
  opacity: 0;
  transition: all 1s ease;
  @media screen and (max-width: $screen-xs){
    transform: translateY(0px);
    opacity: 1;
  }
  @media print {
    transform: translateY(0px);
    transition: none;
  }
}

b, strong{
  font-weight: 600;
}