.blog {
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 130px;
    left: 0;
    right: 0;
  }
  .teaser{
    background: white;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    border-radius: 5px;
    color: $color-grey-base;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    a{
      text-decoration: none;
    }
    &--headline{
      font-size: 17px;
      line-height: 22px;
      margin: 0;
      max-width: 80%;
      text-align: left;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      @media screen and (max-width: $screen-xs-max){
        font-size: 5vw;
      }
    }
    &--summary{
      color: $color-grey-base;
      font-size:14px;
      margin-top:6px;
      padding-right: 21px;
      max-height: 4.3em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      p{
        margin:0;
      }
      ul{
        margin: 0;
        padding:0;
        li{
          display: inline;
          list-style: none;
        }
      }
    }
    &--date{
      color: lighten($color-grey-base, 30%);
      font-size: 11px;
      font-weight: 700;
      i{
        font-size:14px;
        margin-left: -3px;
      }
    }
    &--link{
      position: absolute;
      bottom: 25px;
      width: 100%;
      text-align: center;
      .btn{
        background: white;
        border: 1px solid lighten($color-grey-base, 30%);
        color: lighten($color-grey-base, 30%);
        font-weight: bold;
        width: 90%;
      }
    }
    &--image{
      transition: all 0.4s;
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        min-height: 100%;
        height: auto;
        transition: all 0.4s;
      }
      + .blog-teaser--container{
        margin-top: 0.4em;
      }
      /*&:before{
        content: '';
        background: linear-gradient(to top, rgba(90, 91, 92, 0.5), transparent);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }*/
    }
    &--tags{
      margin: 0 15px;
      padding: 10px 0;
      a{
        @extend .btn--small;
        background: rgba(255,255,255,0.3);
        border-radius: 5px;
        font-size: 10px;
        margin-right: 10px;
        text-transform: unset;
        padding: 3px 12px;
        i{
          font-style: normal;
        }
      }
      &__headline{
        @extend .headline;
        border-top: 1px solid rgba(255,255,255,0.3);
        font-size: 14px;
        color: rgba(255,255,255,0.6);
        letter-spacing: 1px;
        margin-bottom: 0;
        text-transform: none;
        max-width: 100%;
      }
    }
    &--content{
      padding: 25px 15px;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
    }
    &--container,
    &--content{
      height: 270px;
      transition: all 0.4s;
    }
    &--container{
      overflow: hidden;
    }
    &--tags{
      height: 200px;
    }
    &--image{
      height: 230px;
    }
    &:hover{
      .teaser--image{
        height: 0;
      }
      .teaser--container{
        height: 100%;
        background: $brand-secondary;
        box-shadow: 0 0 8px rgba(0,0,0,.4);
        color: white;
      .teaser--content{
        height: 250px;
        padding-top: 60px;
      }
      &--tags{
        height: 250px;
      }
      .teaser--headline,
      .teaser--summary,
      .teaser--date,
      .teaser--link .btn{
        color: white;
      }
      .teaser--link .btn{
        border-color: white;
        background: white;
        color: $brand-secondary;
          &:hover{
            background: $brand-secondary;
            color: white;
            border-color: white;
          }
      }
      }
    }
  }
  &.press__img_fix{
    img{
      width: 162%;
      margin-left: -29%;
      margin-top: -38%;
      min-height: 100%;
      height: auto;
      transition: all 0.4s;
    }
  }
}