.page--stage {
  position: relative;
  display: block;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.page--stage__image {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  height: 500px;
  z-index: -1;
}

.page--stage__btn, 
.page--stage__textarea {
  margin-top: 3%;
  text-align: center;
  background: rgba(255, 255, 255, 0.4);
  padding-top: 10px;
  padding-bottom: 10px;

  h1 {
    color: $brand-secondary;
    font-size: 56px;
    font-weight: 500;
    margin: 0;
    margin-bottom: -8px;
    padding: 0;
  }
  p {
    font-size: 27px;
    color: $brand-secondary;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
}

.page--stage__btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 10px;
  padding-top: 38px;
  padding-bottom: 32px;
}
