.arrow {
  @extend .clearfix;
  text-align: center;
  padding-top: 60px;
  &.bottom {
    padding-top: 0;
    padding-bottom: 60px;
  }

  &.gray {
    .arrow-down {
      background-color: $gray-bright;
    }
  }

  .arrow-down {
    background-color: $brand-secondary;
    width: 30px;
    height: 30px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    margin-top: -10px;
    margin-left: -15px;
    z-index: -1;
    left: 50%;
    &.big {
      padding-top: 30px;
      width: 70px;
      height: 70px;
      margin-top: -35px;
      margin-left: -37px;
    }
  }
}
