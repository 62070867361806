.gallery-three-six {
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 130px;
    left: 0;
    right: 0;
  }
  &__content{
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    grid-gap: 30px;
    padding-bottom: 30px;
    padding-top: 5px;
    &:last-child{
      padding-bottom: 85px;
    }
    @media screen and (max-width: $screen-md) {
      grid-template-columns: auto auto;
    }
    @media screen and (max-width: $screen-xs) {
      grid-template-columns: auto;
    }
  }

  img {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 300px;
    @media screen and (max-width: $screen-md) {
      height: auto;
      width: 100%;
    }
  }

}

.gallery {
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 130px;
    left: 0;
    right: 0;
  }
  &__content{
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    padding-bottom: 20px;
    &:last-child{
      padding-bottom: 85px;
    }
    @media screen and (max-width: $screen-md) {
      grid-template-columns: auto;
    }
    &--image{
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      max-height: 300px;
      overflow: hidden;
    }
  }
}

.video-gallery{
  padding: 75px 0 0;
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 205px;
    left: 0;
    right: 0;
  }
  .gallery__content{
    &--image{
      background-size: cover;
      display: block;
      height: 300px;
      position: relative;
      width: 100%;
      &:hover{
        .video__btn{
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
    &--description{
      color: white;
      bottom: 1em;
      left: 1em;
      position: absolute;
      -moz-transform-style: preserve-3d;
      &__header,
      &__subheader{
        display: block;
      }
      &__subheader{
        font-size: 14px;
      }
    }
    .video__btn{
      transition: all 0.4s;
      opacity: 0.4;
    }
  }
  .video__btn{
    text-align: center;
    top: 45%;
    position: absolute;
    width: 100%;
    z-index: 2;
    a{
      color: white;
      display: inline-block;
      &:before{
        font-family: fontello;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        box-shadow: none;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 2em;
        text-align: center;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: none;
        content: '\e801';
        border: 1px solid white;
        border-radius: 100%;
        padding: 0.4em 0.3em 0.4em 0.4em;
        margin-right: 5px;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
  .video__content{
    margin: 0 auto;
    left: 17%;
    display:none;
    position: fixed;
    top: 20%;
    width: 70%;
    transition: all 0.4s;
    z-index: 1000;
  }
}

#gallery-overlay{
  margin: 0 auto;
  left: 17%;
  display:none;
  position: fixed;
  top: 7vh;
  width: 70%;
  z-index: 1000;
  @media screen and (max-width: $screen-md) {
    left: 0;
    width: 100%;
  }
}

#gallery-mask,
#gallery-overlay-mask,
.gallery-overlay-mask{
  background:rgba(0,0,0,0.5);
  display:none;
  left:0;
  height:100%;
  position:fixed;
  top:0;
  transition: all 0.4s;
  width:100%;
  z-index:500;
}

.gallery-three-six,
.gallery{
  &.bestand{
    &:before{
      background: lighten($bestand, 45%);
    }
  }
  &.finanzierung{
    &:before{
      background: lighten($finanzierung, 45%);
    }
  }
}