/* ####### FADE IN AND OUT ANIMATION GALLERY ######## */

.fade-animation.number_elements_1 {
  a {
    img {
      &.hiddenanimation {
        opacity: 1;
      }
    }
  }
}

.fade-animation.number_elements_2 {
  a {
    img {
      &:nth-of-type(3) {
        animation-name: fadeInAndOutGalF2;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 10s;
        animation-direction: alternate;
      }
    }
  }
}

/* Animation for 3 images until 10 */
@for $num from 3 through 10 {
  $presentation_time: 2; /* presentation time for one image */
  $cross_time: 2; /* duration for cross fading */
  $total_time: $presentation_time + $cross_time * $num; /* total animation-duration */
  $steps: $total_time / $num; /* steps for animation-delay */
  $p1: $presentation_time / $total_time * 100%; /* percentage no 1 */
  $p2: 1 / $num * 100%; /* percentage no 2 */
  $p3: 100% - ($cross_time / $total_time * 100%); /* percentage no 3 */
  @-webkit-keyframes fadeInAndOutGalF#{$num} {
    0%  {
      opacity: 1;
    }
   #{$p1 } {
      opacity: 1;
    }
   #{$p2 } {
      opacity: 0;
    }
  #{$p3} {
      opacity: 0;
    }
    100%  {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeInAndOutGalF#{$num} {
    0%  {
      opacity: 1;
    }
   #{$p1 } {
      opacity: 1;
    }
   #{$p2 } {
      opacity: 0;
    }
  #{$p3} {
      opacity: 0;
    }
    100%  {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeInAndOutGalF#{$num} {
    0%  {
      opacity: 1;
    }
   #{$p1 } {
      opacity: 1;
    }
   #{$p2 } {
      opacity: 0;
    }
  #{$p3} {
      opacity: 0;
    }
    100%  {
      opacity: 1;
    }
  }
  @-o-keyframes fadeInAndOutGalF#{$num} {
    0%  {
      opacity: 1;
    }
   #{$p1 } {
      opacity: 1;
    }
   #{$p2 } {
      opacity: 0;
    }
  #{$p3} {
      opacity: 0;
    }
    100%  {
      opacity: 1;
    }
  }
  @keyframes fadeInAndOutGalF#{$num} {
    0%  {
      opacity: 1;
    }
   #{$p1 } {
      opacity: 1;
    }
   #{$p2 } {
      opacity: 0;
    }
  #{$p3} {
      opacity: 0;
    }
    100%  {
      opacity: 1;
    }
  }
  .fade-animation.number_elements_#{$num} {
    a {
      img {
        animation-name: fadeInAndOutGalF + $num;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: $total_time * 1s;
        $num: $num + 1;
        @for $no from 2 through $num {
          &:nth-of-type(#{$no}) {
            animation-delay: $total_time - $steps * 1s;
          }
          $total_time: $total_time - $steps; /* steps */
        }
      }
    }
  }


  @-webkit-keyframes fadeInAndOutGalF2  {
    0%  {
      opacity: 1;
    }
    45%  {
      opacity: 1;
    }
    55%  {
      opacity: 0;
    }
    100%  {
      opacity: 0;
    }
  }


  @-moz-keyframes fadeInAndOutGalF2  {
    0%  {
      opacity: 1;
    }
    45%  {
      opacity: 1;
    }
    55%  {
      opacity: 0;
    }
    100%  {
      opacity: 0;
    }
  }


  @-ms-keyframes fadeInAndOutGalF2  {
    0%  {
      opacity: 1;
    }
    45%  {
      opacity: 1;
    }
    55%  {
      opacity: 0;
    }
    100%  {
      opacity: 0;
    }
  }


  @-o-keyframes fadeInAndOutGalF2  {
    0%  {
      opacity: 1;
    }
    45%  {
      opacity: 1;
    }
    55%  {
      opacity: 0;
    }
    100%  {
      opacity: 0;
    }
  }


  @keyframes fadeInAndOutGalF2  {
    0%  {
      opacity: 1;
    }
    45%  {
      opacity: 1;
    }
    55%  {
      opacity: 0;
    }
    100%  {
      opacity: 0;
    }
  }
}
