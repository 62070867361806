$carouselHeight: 35em;
$stageCarouselHeight: 100vh;
$stageCarouselHeighMobile: 20em;
$carouselElem: 20;

.carousel {
  border-radius: 5px;
  height: $carouselHeight;
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding: 0;
  list-style: none;
  .gallery-stage &{
    height: $stageCarouselHeight;
  }
  @media screen and (max-width: $screen-md) {
    .gallery-stage &{
      height: $stageCarouselHeighMobile;
    }
  }
}
.carousel__controls,
.carousel__activator {
  display: none;
}

@for $i from 1 through $carouselElem{
  .carousel__activator:nth-of-type( #{$i} ):checked ~ .carousel__slide:nth-of-type( #{$i} ) {
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@for $i from 1 through $carouselElem{
  .carousel__activator:nth-of-type( #{$i} ):checked ~ .carousel__controls:nth-of-type( #{$i} ) {
    display: block;
    opacity: 1;
  }
}
@for $i from 1 through $carouselElem{
  .carousel__activator:nth-of-type( #{$i} ):checked ~ .carousel__indicators .carousel__indicator:nth-of-type( #{$i} ) {
    opacity: 1;
    transform: scale(1.2);
  }
}
.carousel__activator:nth-of-type(1):checked ~ .carousel__track {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
$percent: 100%;
@for $i from 2 through $carouselElem{
  .carousel__activator:nth-of-type(#{$i}):checked ~ .carousel__track {
    -webkit-transform: translateX( -$percent );
    transform: translateX( -$percent );
  }
  $percent: $percent + 100;
}

.carousel__control,
.arrow{
  display: block;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  font-weight: 600;
  cursor: pointer;
  color: white;
  position: absolute;
  padding-top: 0;
  width: 30px;
  height: 30px;
  top: 45%;
  z-index: 3;
  &:hover{
    .arrow-border{
      transform: scale(1.2);
    }
  }
  &::after {
    font-family: fontello;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    box-shadow: none;
    text-align: center;
    speak: none;
    display: inline-block;
    width: 30px;
    padding-top: 30%;
    transform-origin: center;
    height: 3.2em;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: none;
    content: "\e805";
  }
  .arrow-border{
    border-radius: 100%;
    border: 1px solid white;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    transition: all 0.3s;
  }
  &.left{
    left: 1em;
    &:after{
      transform: rotate(90deg);
    }
  }
  &.right{
    right: 1em;
    &:after{
      transform: rotate(-90deg);
    }
  }
}

.carousel__indicators {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.carousel__indicator {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
  opacity: 0.35;
  margin: 0 2.5px 0 2.5px;
}
.carousel__indicator:hover {
  opacity: 0.75;
}
.carousel__track {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}
.carousel__track .carousel__slide {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.carousel__track .carousel__slide:nth-of-type(1) {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.carousel__slide:nth-of-type(1),
.carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(1) {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: $screen-md) {
    background-size: contain;
  }
}

$percent: 100%;
@for $i from 2 through $carouselElem{
  .carousel__track .carousel__slide:nth-of-type( #{$i} ) {
    -webkit-transform: translateX($percent);
    transform: translateX($percent);
    margin-top: -$carouselHeight;
    .gallery-stage &{
      margin-top: -$stageCarouselHeight;
    }
    @media screen and (max-width: $screen-md) {
      .gallery-stage &{
        margin-top: -$stageCarouselHeighMobile;
      }
    }
  }
  $percent: $percent + 100;
  .carousel__slide:nth-of-type(  #{$i} ),
  .carousel--thumb .carousel__indicators .carousel__indicator:nth-of-type(  #{$i} ) {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $screen-md) {
      background-size: contain;
    }
  }
}

.carousel--scale .carousel__slide {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.carousel__slide {
  height: 100%;
  overflow-y: auto;
  opacity: 0;
  @media screen and (max-width: $screen-md) {
    .gallery-stage &{
      background-size: cover;
    }
  }
}

.carousel__slide {
  overflow: hidden;
}
.carousel--thumb .carousel__indicator {
  height: 30px;
  width: 30px;
}
.carousel__indicator {
  background-color: #fafafa;
}