footer {
  background-color: $background;
  color: darken($background, 25%);
  font-size: 0.8em;
  line-height: 25px;
  text-align: left;
  @media screen and (max-width: $screen-xs-max) {
    padding: 0 0 20px;
  }
  small,
  p {
    display: block;
    margin: 10px 0;
    padding: 5px 10px;
  }
  h4 {
    display: inline-block;
    font-size: 1em;
    margin: 0;
    padding: 5px 10px 0;
  }

  .footer_siegel{
    background: $background;
    max-width: 100%;
    overflow: hidden;
    ul{
      align-items: center;
      border-top: 1px solid $light-blue;
      display: flex;
      margin: 0 25px 0 25px;
      padding: 0;
      @media screen and (max-width: $screen-xs-max) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
      li{
        list-style: none;
        width: calc(100% / 6);
        padding: 2em;
        @media screen and (max-width: $screen-xs-max) {
          width: calc(190% / 6);
          padding: 1em;
          display: inline-block;
        }
      }
    }
    img{
      width: 100%;
    }
  }

  .footer_links {
    background: $footer-background;
    &__links{
      &.project-footer{
        text-align: center;
      }
      .copyright{
        display: block;
      }
      a {
        color: white;
        display: inline-block;
        padding: 15px 20px;
        font-size: 14px;
        text-align: center;
        @media screen and (max-width: $screen-md) {
          border-bottom: 1px solid $brand-secondary;
          width: 50%;
        }
        @media screen and (max-width: $screen-md) {
          border-radius: 3px;
          border-bottom: 0;
          background: $light-blue;
          font-weight: 600;
          margin-bottom: 5px;
          text-transform: uppercase;
          width: 100%;
        }
        &:focus,
        &:hover {
          color: lighten($gray-base, 80%);
          text-decoration: none;
        }
        &:first-child {
          font-weight: bold;
          padding-left: 10px;
          @media screen and (max-width: $screen-xs-max) {
            font-weight: 600;
          }
        }
      }
      @media screen and (max-width: $screen-xs-max) {
        background: white;
        text-align: center;
      }
    }

    &__overview{
      background-color: $background;
      color: $brand-secondary;
      padding-top: 30px;
      .container {
        padding-bottom: 20px;
        @media screen and (max-width: $screen-xs-max) {
          padding-bottom: 0;
        }
      }
      input#accordion-1 {
        ~ .accordion-mobile-content {
          max-height: 100%;
        }
        + label:after{
          content:'';
        }
      }
      ul{
        margin:0;
        padding:1em 0 0 0;
        li{
          list-style: none;
          a{
            color:$brand-secondary;
          }
        }
      }
      p{
        padding:0;
      }
      label{
        display: block;
        border-bottom: 1px solid $light-blue;
        width: 100%;
        line-height: 3;
        text-transform: uppercase;
        font-weight: 600;
      }
      &--column{
        &:first-child{
          padding-left: 25px;
        }
        &:last-child{
           padding-right: 25px;
         }
        &:first-child{
          &>*{
            padding-left: 0;
          }
        }
        @media screen and (max-width: $screen-md){
          padding: 0 25px 0 25px;
          &:last-child label{
            border-bottom: 0;
            margin-bottom: 5px;
          }

        }
      }
      @media screen and (min-width: $screen-md){
        .container{
          display: flex;
          &>:first-child{
            flex:1;
          }
        }
        &--column{
          &>*{
            padding: 1em;
          }
        }
        label{
          display: block;
          line-height: 30px;
          padding-bottom:0.5em;
          width: 100%;
        }
      }
    }
  }
}
