#openSidebarMenu,
.sidebarIconToggle,
.nav__mobile{
  display: none;
}

@media screen and (max-width: $screen-md-min) {
  .sidebarIconToggle{
    display: block;
  }
  .nav__mobile{
    position:fixed;
    top:0;
    left:0;
    width:250px;
    height:100%;
    margin:0 0 0 -250px;
    -moz-transition:all 200ms ease-in;
    -webkit-transition:all 200ms ease-in;
    -o-transition:all 200ms ease-in;
    transition:all 200ms ease-in;
  }
  .header-nav {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    position: fixed;
    height: 60px !important;
    z-index: 10;
    .nav{
        text-align: left;
      &--mobile{
        display: block !important;
        .exporo-auth-button-logout{
          display: none;
        }
        &__logout{
          .exporo-auth-button-logout {
            display: block;
            padding: 17px 10px 17px 30px;
          }
        }
        .auth-btn-text{
          display: inline-block;
        }
      }
      ul{
        display: block;
        height: calc(100% - 60px);
        position: relative;
      }
      &__navigation {
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        color: $color-grey-base;
        margin: 0;
        padding: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        li {
          list-style: none;
          color: $color-grey-base;
          text-transform: uppercase;
          padding: 0;
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, 0.10);
          display: block;
          line-height: 1;
          &.nav--mobile{
            padding: 10px 15px 25px 15px;
            text-align: center;
            .auth-btn{
              font-size: 14px;
              border-width: 1px;
              width: 100%;
              padding: 10px;
            }
            .sidebar__logo{
              padding: 0 0 2em;
              width: 60%;
            }
            .exporo-auth-button{
              display: block;
            }
          }
          .collapse &{
            line-height: 1;
          }
          a {
            color: $color-grey-base;
            text-transform: uppercase;
            cursor: pointer;
            text-decoration: none;
            line-height: 1;
            padding: 17px 10px 17px 35px;
            width: 100%;
            span {
              font-size: 14px;
            }
            .firstStage &{
              color: $color-grey-base;
            }
            .collapse &{
              line-height: 1;
            }
          }
        }
      }
    }

  }
  #sidebarMenu {
    position:fixed;
    top:0;
    left:0;
    width:260px;
    height:100vh;
    margin:0 0 0 -260px;
    -moz-transition:all 200ms ease-in;
    -webkit-transition:all 200ms ease-in;
    -o-transition:all 200ms ease-in;
    transition:all 200ms ease-in;
    ul{
      height: 100%;
    }
    /*height: 100%;
    position: fixed;
    left: 0;
    width: 250px;
    margin-top: 60px;
    transform: translateX(-253px);
    transition: transform 250ms ease-in-out;*/
    ~.header-logo{
      left: calc(50% - 4em);
      .collapse &{
        margin: 0;
        top: 16px;
      }
    }
    .auth-btn{
      color: $color-grey-base;
      border-color: $color-grey-base;
    }
  }
  
  /*input.openSidebarMenu[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
  }*/

  input.openSidebarMenu[type=checkbox] {
    transition: all 0.3s;
    display: none;
  }
  input.openSidebarMenu[type="checkbox"]:checked ~ nav{
    margin:0;
  }
  /*input.openSidebarMenu[type="checkbox"]:checked ~ label.sidebarIconToggle{
    left:260px;
  }*/
  .wrapper{
    -moz-transition:all 200ms ease-in;
    -webkit-transition:all 200ms ease-in;
    -o-transition:all 200ms ease-in;
    transition:all 300ms ease-in;
  }
  input.openSidebarMenu[type="checkbox"]:checked ~ .wrapper{
    -webkit-transform:translate3d(260px, 0, 0);
    -moz-transform:translate3d(260px, 0, 0);
    -o-transform:translate3d(260px, 0, 0);
    transform:translate3d(260px, 0, 0);
    position: fixed;
    .exporo-overlay{
      -webkit-transform:translate3d(-260px, 0, 0);
      -moz-transform:translate3d(-260px, 0, 0);
      -o-transform:translate3d(-260px, 0, 0);
      transform:translate3d(-260px, 0, 0);
      overflow: auto;
    }
  }

  .sidebarIconToggle {
    transition: all 0.3s;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
    &:before{
      content:"";
      transition: all 0.3s;
    }
  }
  .nav-burger {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: $color-grey-base;
    .firstStage &{
      background-color: #fff;
    }
    .collapse &{
      background-color: $color-grey-base;
    }
  }
  .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
    width: 80%;
  }
  .diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
  }
  .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
    width: 60%;
  }
  input.openSidebarMenu[type=checkbox]:checked ~ .wrapper .sidebarIconToggle{
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.4);
      position: fixed;
      left: 0;
      top: 0;
    }
  }
  input.openSidebarMenu[type=checkbox]:checked ~ .wrapper .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    background-color: #fff;
    opacity: 0;
  }
  input.openSidebarMenu[type=checkbox]:checked ~ .wrapper .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    background-color: #fff;
    transform: rotate(135deg);
    margin-top: 8px;
    width: 100%;
  }
  input.openSidebarMenu[type=checkbox]:checked ~ .wrapper .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    background-color: #fff;
    transform: rotate(-135deg);
    margin-top: -9px;
    width: 100%;
  }
}