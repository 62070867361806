.faq {
  padding: 50px 0;
  position: relative;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }
  &__headline{
    @extend .headline;
    padding: 0 25px;
  }
  &__subline {
    @extend .subline;
    padding: 0 25px;
  }
  &__content {
    margin: 25px 0;
    padding: 5px 0;
    &>*{
      padding: 0 25px;
    }
    &-headline {
      color:$brand-secondary;
      font-weight: bold;
      line-height:30px;
      margin-bottom:20px;
      text-transform: uppercase;
    }
    &-text {
      background: $background;
      border-bottom: 1px solid $color-grey-base;
      border-top: 1px solid $color-grey-base;
      cursor: pointer;
      display: block;
      list-style: none;
      padding: 20px 25px;
      position: relative;
      ~ .faq__content-text{
        border-top: 0;
      }
    }
    label{
      font-size: 18px;
      display: block;
      line-height: 1;
      padding-right: 30px;
      width: 100%;
      &:after{
        padding: 0.4em;
        display: flex;
        align-items: center;
      }
    }
    p{
      margin-left: 0;
    }
  }
}
