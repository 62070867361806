.video-quote {
    padding-top: 40px;
    margin-bottom: 60px;
    &__background {
        position: absolute;
        height: calc(100% - 125px);
        width: 100%;
        z-index: -1;
        bottom: 0;
    }
    &__container {
        margin: 0 auto;
        max-width: 800px;

        &--video {
            max-width: 700px;
        }
        &--video {
            img {
                max-width: 100%;
                display: block;
            }
            overflow: hidden;
            border-radius: 5px;
            box-shadow: 0 0 8px rgba(0,0,0,.2);
            @media screen and (max-width: $screen-xs-max) {
                margin: 0 1em;
            }
        }
        &--quote {
            blockquote {
                font-family: "Oswald", sans-serif;
                font-size: 30px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.6;
                text-align: center;
                @media screen and (max-width: $screen-sm) {
                    font-size: 22px;
                }
                &:before {
                    display: none;
                }
            }

        }
        &--label {
            color: white;
            font-family: "Oswald", sans-serif;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            max-width: 600px;
            margin-top: 50px;
            text-transform: uppercase;
            padding: 15px 0;
            position: relative;
            transform: translateY(50%);
            .video-quote__arrow {
                width: 0;
                height: 0;
                border-left: 40px solid transparent;
                border-right: 40px solid transparent;
                border-top: 25px solid;
                margin: 0 auto;
                left: 0;
                right: 0;
                position: absolute;
                bottom: -24px;
            }
        }
        .icon{
            font-size:30px;
        }
    }
    &__photo {
        margin: 0 auto;
        display: block;
        border: 5px solid #fff;
        border-radius: 120px;
        box-shadow: 0 0 8px rgba(0,0,0,.2);
        overflow: hidden;
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center center;
        margin-top: 100px;
    }
    &__person {
        margin: 40px auto 0;
        text-align: center;
        font-weight: 700;
        img {
            display: block;
            width: 32px;
            height: 32px;
            border-radius: 60px;
            overflow: hidden;
            margin: 0 auto;
        }
        &-name {
            margin-top: 10px;
            font-family: "Oswald", sans-serif;
            text-transform: uppercase;
        }
        &-role {
            margin-top: 5px;
            font-size: 12px;
        }
    }
}
.bestand{
    .video-quote__background{
        background: lighten($bestand, 45%);
    }
    .video-quote__container--label{
        background: $bestand;
    }
    .icon,
    .video-quote__person-name{
        color: $bestand;
    }
    .video-quote__arrow{
        border-top-color: $bestand;
    }
}
.finanzierung{
    .video-quote__background{
        background: lighten($finanzierung, 45%);
    }
    .video-quote__container--label{
        background: $finanzierung;
    }
    .icon,
    .video-quote__person-name{
        color: $finanzierung;
    }
    .video-quote__arrow{
        border-top-color: $finanzierung;
    }
}
