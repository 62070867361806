.closed-projects {
    .wrap {
        max-width: 1060px;
        padding: 90px 30px;
    }
    &__filters {
        display: flex;
        flex-wrap: wrap;
    }
    &__filter {
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        &:after {
            right: 15px;
            top: 18px;
            position: absolute;
            display: block;
            content: "\25BC";
            font-size: 8px;
            color: #fff;
        }
        select {
            background: $brand-secondary;
            color: #fff;
            border: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            padding: 10px 40px 10px 20px;
            border-radius: 100px;
            transition: border .3s ease;
            &:focus {
                outline: none;
            }
        }
    }
    &__grid {
        margin-top: 25px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 18px;
        grid-column-gap: 16px;
    }
    &__project {
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        max-width: 316px;
        overflow: hidden;
        transition: all .3s ease;
        &:hover,
        &:focus {
            text-decoration: none;
        }
        &:focus {
            box-shadow: 0 0 10px transparentize($brand-secondary, .3);
        }
        &--Finanzierung .closed-projects__state {
            background-color: $finanzierung;
        }
        &--Bestand .closed-projects__state {
            background-color: $bestand;
        }
    }
    &__state {
        background-color: $brand-secondary;
        padding: 5px 15px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
    }
    &__image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center center;
        background-color: lightgrey;
    }
    &__kpis {
        list-style: none;
        padding: 5px 0;
        margin: 0;
    }
    &__kpi {
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        padding: 7px 20px;
        font-weight: bold;
        &-key {
            color: #a0a0a0;
        }
        &-value {
            color: #626262;
        }
        &:first-child{
            flex-direction: column-reverse;
            position: relative;
            min-height: 52px;
            .closed-projects__kpi-value {
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        &:last-child{
            border-bottom: none
        }
    }
    &__badge {
        position: absolute;
        top: 9px;
        right: 10px;
        border-radius: 20px;
        color: #fff;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ccc;
        font-weight: 400;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        font-size: 15px;
        text-transform: uppercase;
        & ~ div {
            width: calc(100% - 30px);
        }
    }
    &__ratings {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px 15px;


        &--selected {
            &- {
                padding: 20px 10px 25px;
            }
            &-AA .closed-projects__rating--aa,
            &-A .closed-projects__rating--a,
            &-B .closed-projects__rating--b,
            &-C .closed-projects__rating--c,
            &-D .closed-projects__rating--d,
            &-E .closed-projects__rating--e,
            &-F .closed-projects__rating--f {
                font-size: 30px;
                width: 50px;
                height: 50px;
            }
        }
    }
    &__rating {
        color: #fff;
        font-size: 15px;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 2px;
        font-weight: 400;
        text-shadow: 0 0 2px rgba(0, 0, 0, .4);
        &--active {
            font-size: 30px;
            width: 50px;
            height: 50px;
        }
        @each $rating, $value in $investment-rating-map {
            &--#{$rating} {
                background-color: $value;
            }
        }
    }
}

@media screen and (max-width: $screen-md) {
    .closed-projects {
        &__filters {
            justify-content: center;
        }
        &__grid {
            grid-template-columns: 320px 320px;
            justify-content: center;
        }
    }
}

@media screen and (max-width: $screen-sm) {
    .closed-projects {
        &__grid {
            grid-template-columns: 316px;
        }
    }
}

@media screen and (max-width: $screen-xs) {
    .closed-projects {
        .wrap {
            padding: 90px 10px;
        }
        &__grid {
            grid-template-columns: 100%;
        }
        &__project {
            max-width: 100%;
        }
    }
}