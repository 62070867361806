.masonry {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  min-height: 50em;
  max-width: 100%;
  .masonry-brick {
    width: calc(33.33333% - 10px);
    margin: 0.2em;
    @media screen and (max-width: $screen-md) {
      width: calc(50% - 10px);
    }
    @media screen and (max-width: $screen-xs-max) {
      width: calc(100% - 10px);
    }
  }
}

.masonry-grid {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 32%);
  grid-gap: 25px;
  padding-bottom: 85px;
  padding-top: 5px;
  @media screen and (max-width: $screen-md) {
    grid-template-columns: repeat(2, 45%);
  }
  @media screen and (max-width: $screen-xs-max) {
    grid-template-columns: repeat(1, 100%);
  }
  .glossar &{
    grid-auto-rows: 255px;
  }
  .blog &{
    grid-auto-rows: 500px;
  }
  .products &{
    grid-template-columns: repeat(2, 48%);
    grid-auto-rows: 660px;
    grid-gap: 50px;
    @media screen and (max-width: $screen-xs-max) {
      grid-auto-rows: auto;
      grid-template-columns: repeat(1, 100%);
    }
  }
  .project__overview &{
    grid-auto-rows: minmax(500px, auto);
    @media screen and (max-width: $screen-xs-max) {
      grid-auto-rows: auto;
    }
  }
  .location &{
    grid-auto-rows: 200px;
    grid-template-columns: repeat(2, 50%);
    @media screen and (max-width: $screen-md) {
      grid-template-columns: repeat(2, 49%);
    }
    @media screen and (max-width: $screen-xs-max) {
      grid-auto-rows: auto;
      grid-template-columns: repeat(1, 100%);
    }
  }
  .network &{
    grid-auto-rows: 250px;
  }
}

.plain-overview{
  .project__overview{
    .masonry-grid{
      grid-auto-rows: minmax(300px, auto);
      @media screen and (max-width: $screen-xs-max) {
        grid-auto-rows: auto;
      }
    }
    .teaser--container{
      height: calc(100% + 50px);
      @media screen and (max-width: $screen-xs-max){
        height: auto;
      }
    }
    .teaser--link{
      position: initial;
      margin-bottom: 1em;
    }
  }
}
