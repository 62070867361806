.benefits {
  font-family: 'Oswald', sans-serif;
  padding: 25px 0 20px 0;
  position: relative;
  .wrap {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $screen-md) {
      display: block;
      height: 100%;
    }
  }
  @media screen and (max-width: $screen-xs) {
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
  }
  &__background-blue {
    background: $brand-secondary;
    color: white;
    .benefits__value--text,
    .benefits__desc {
      color: white;
    }
  }
  &__background-grey {
    background: $color-grey-base;
    color: white;
    .benefits__value--text, 
    .benefits__desc {
      color: white;
    }
  }
  &__background-white {
    background: white;
    .benefits__value--text {
      color: $color-grey-base;
    }
    .benefits__desc {
      color: $color-grey-base;
    }
  }

  .stage__scrolldown {
    display: none;
    position: absolute;
    bottom: 0;
    height: 110px;
    width: 145px;
    left: auto;
    right: -13vw;
    #scrolldown-circle {
      animation: bounce 1s infinite alternate;
    }
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

.benefits__item {
  flex: 1;
  text-align: left;
  padding-right: 40px;
  @media screen and (max-width: $screen-xs-max) {
    margin-top: 2em;
    &:first-child{
      margin-top: -10px;
    }
  }
  .inner {
    position: relative;
    padding: 0;
    display: inline-block;
    text-align: left;
  }
}

.benefits__value {
  display: inline-block;
  @media screen and (max-width: $screen-xs-max) {
    padding: 0;
  }
  span {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
    text-transform: none;
    p {
      margin: 0 0;
    }
  }
}
.benefits__value--text {
  @extend .headline;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 50px;
  color: white;
  max-width: 100%;
  @media screen and (max-width: $screen-xs-max) {
    font-size: 9vw;
    line-height: 10vw;
    word-break: break-word;
  }
}

.benefits__font-size-icons {
  &-large {
    .benefits__value--text {
      font-size: 19px;
    }
  }
  &-normal {
    .benefits__value--text {
      font-size: 38px;
      @media print {
        font-size: 19px;
      }
    }
  }
}

.benefits__desc {
  font-size: 14px;
  font-weight: 400;
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media print {
    font-size: 12px;
  }
  @media screen and (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
  a {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

.stage {
  + .benefits {
    height: 125px;
    max-width: 1080px;
    margin: -125px auto 0;
    z-index: 50;
    @media screen and (max-width: $screen-md) {
      height: auto;
      margin: -92px 0 0;
      width: 80%;
      .benefits__value {
        span {
          text-align: left;
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: -1500px;
      top: 0;
      bottom: 0;
      right: 50%;
      background: white;
      @media screen and (max-width: $screen-md) {
        left: 0;
        background: transparent;
      }
    }
    .benefits__item {
      text-align: left;
    }
    .benefits__value:first-child {
      padding-left: 0;
    }
    .stage__scrolldown{
      display: block;
    }
  }

  &.product{
    + .benefits {
      display: none;
    }
  }
}
.stage {
  + .benefits__background-grey {
    &:before{
      background: $color-grey-base;
    }
  }
  + .benefits__background-blue {
    &:before{
      background: $brand-secondary;
    }
  }
}

@-webkit-keyframes bounce {
  from  {
    transform: translateY(0px);
  }
  to  {
    transform: translateY(-3px);
  }
}


@-moz-keyframes bounce {
  from  {
    transform: translateY(0px);
  }
  to  {
    transform: translateY(-3px);
  }
}


@-ms-keyframes bounce {
  from  {
    transform: translateY(0px);
  }
  to  {
    transform: translateY(-3px);
  }
}


@-o-keyframes bounce {
  from  {
    transform: translateY(0px);
  }
  to  {
    transform: translateY(-3px);
  }
}


@keyframes bounce {
  from  {
    transform: translateY(0px);
  }
  to  {
    transform: translateY(-3px);
  }
}
