.project {
  padding: 75px 0 0;
  @media screen and (max-width: $screen-xs-max) {
    padding: 55px 0 0;
    &:before {
      content: '';
      background: $background;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  &__overview {
    background: white;
    padding: 15px 0;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      background: $background;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 140px;
      left: 0;
      right: 0;
      @media screen and (max-width: $screen-xs-max) {
        top: 0;
      }
    }
    .teaser {
      background: white;
      box-shadow: 0 0 8px rgba(0, 0, 0, .2);
      border-radius: 5px;
      color: $color-grey-base;
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
      text-align: left;
      transition: all 0.4s;
      order: 2;
      a {
        color: $color-grey-base;
        display: block;
        height: 100%;
        text-decoration: none;
        @media screen and (min-width: $screen-xs-max) {
          &.teaser--container {
            height: 510px;
          }
        }
      }
      &--image {
        height: 200px;
        transition: all 0.4s;
        position: relative;
        overflow: hidden;
        img {
          height: 100%;
          min-width:100%;
          transition: all 0.4s;
          object-fit: cover;
          object-position: center center;
        }
        .miles_and_more {
          display: block;
          cursor: pointer;
          right: -6px;
          bottom: -1px;
          position: absolute;
          img {
            width: 100%;
            height: 8em;
          }
        }
        .ribbon_text {
          color: white;
          box-shadow: 0 0 8px rgba(0, 0, 0, .2);
          border-radius: 0 0 5px 0;
          font-size: 12px;
          font-weight: bold;
          display: block;
          left: 0;
          top: 0;
          padding: 0.5em 1.5em;
          position: absolute;
        }
      }
      &--container {
        height: 500px;
        position: relative;
        @media screen and (max-width: $screen-xs-max){
          height: auto;
        }
        &__headline {
          font-size: 18px;
          font-weight: bold;
          padding: 1em 0.8em 0;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: unset;
          max-height: 3em;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: unset;
        }
        &__subline {
          color: lighten($color-grey-base, 25%);
          font-size: 10px;
          font-weight: bold;
          padding: 0 0 1em 1.5em;
        }
        & > p {
          margin: 0;
          padding: 1em;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            text-align: left;
          }
        }
      }
      &--summary {
        & > * {
          border-bottom: 1px solid $gray-bright;
          display: block;
          font-size: 14px;
          line-height: 1.5em;
          height: auto;
          padding: 0.5em 1em;
          &:last-child {
            border-bottom: 0;
          }
        }
        &__onerow{
          height: auto;
          padding: 0.5em 1em;
          &:last-child{
            @media screen and (max-width: $screen-xs-max) {
              padding:  0.5em 1em 1em;
            }
          }
          &.warning{
            text-align: center;
          }
        }
        .left,
        .right {
          display: inline-block;
          vertical-align: bottom;
          width: 49%;
        }
        .right {
          text-align: right;
        }
        .value,
        .desc {
          display: block;
        }
        .value {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.2;
          &.big {
            font-size: 24px;
          }
        }
        .teaser--process-bar {
          width: 100%;
          height: 20px;
        }
        .desc {
          color: lighten($color-grey-base, 25%);
          font-size: 10px;
          font-weight: bold;
        }
      }
      &--link {
        position: absolute;
        bottom: 1em;
        text-align: center;
        padding: 0;
        width: 100%;
        @media screen and (max-width: $screen-xs-max){
          position: static;
          margin-bottom: 1em;
        }
        .btn {
          box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
          font-weight: bold;
          padding: 13px 20px;
          width: 90%;
          @media screen and (max-width: $screen-xs-max) {
            font-weight: 600;
            padding: 10px 20px;
          }
        }
      }

    }

    &-content {
      @media screen and (min-width: $screen-sm-max){
        grid-column-start: 1;
        grid-column-end: 4;
        order: 1;
      }

      .teaser {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        @media screen and (max-width: $screen-sm-max) {
          display: block;
        }
        @media screen and (min-width: $screen-xs-max) {
          &--image {
            width: 100%;
            flex: 2;
            height: 510px;
            img {
              width: 100%;
            }
          }
          &--container {
            height: 100%;
            height: 500px;
            padding-bottom: 4em;
            flex: 1;
            &__headline {
              height: 2.5em;
              padding: 1em 1em 0 1em;
              margin-top: 0;
              max-height: 100%;
            }
            &__subline{
              border-bottom: 1px solid $gray-bright;
              padding: 0 0 1em 1.8em
            }
          }
          &--summary {
            &__onerow{
              .left,
              .right {
                border-bottom: 1px solid $gray-bright;
                font-size: 14px;
                height: 4em;
                padding: 1.2em 1em 1em;
                text-align: left;
                width: 100%;
                line-height: 1.3em;
              }
            }
            &__three .right {
              border-bottom: 0;
            }
            &:after {
              content: "";
              clear: none;
              display: table;
            }
          }
        }
        @media screen and (min-width: $screen-xs-max) and ( max-width: $screen-md-min) {
          &--image {
            height: 200px;
          }
          &--container {
            height: 500px;
          }
        }
      }
      ~ .wrap.masonry-grid {
        margin-top: -50px;
      }
    }

  }

  &-special {
    padding-bottom: 45px;
    &__headline {
      background: $bestand;
      border-radius: 5px 5px 0 0;
      color: white;
      font-size: 12px;
      font-weight: bold;
      padding: .8em 1em .7em;
      text-align: center;
      text-transform: uppercase;
    }
    &__table {
      display: table;
      font-size: 12px;
      border-collapse: collapse;
      text-transform: uppercase;
      width: 100%;
      &-heading {
        display: table-header-group;
      }
      &-head {
        display: table-cell;
        font-weight: bold;
        padding: 1em;
        &:nth-child(2) {
          background: darken($background, 1%);
        }
      }
      &-body {
        display: table-row-group;
      }
      &-row {
        display: table-row;
        .project-special__table-heading &,
        &:nth-child(even) {
          background: lighten($bestand, 30%);
          .project-special__table-cell {
            &:nth-child(2) {
              background: lighten($bestand, 35%);
            }
          }
        }
      }
      &-cell {
        display: table-cell;
        padding: 1em;
        &:nth-child(2) {
          background: darken($background, 1%);
        }
      }
      .btn {
        background: $bestand;
        border-color: $bestand;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
        padding: 13px 30px;
        @media screen and (max-width: $screen-xs-max) {
          font-weight: 600;
          padding: 10px;
        }
      }
    }
  }

  .teaser {
    &__progress {
      border-bottom: 1px solid $gray-bright;
      padding: 1em;
      &-text {
        line-height: 1;
        color: $color-grey-base;
        font-size: 15px;
        font-weight: 600;
        &--percentage {}
        &--absolute {
          margin-left: 5px;
          font-size: 10px;
          font-weight: bold;
        }
      }
      &-bar {
        width: 100%;
      } 
    }
    .exporo-progress {
      .exporo-progress-root{
        padding: 0 !important;
      }
      .info {
        &>*{
          display: inline-block;
          vertical-align: top;
          width: 49%;
        }
        span {
          font-size: 16px;
          font-weight: 600;
        }
        p {
          font-size: 10px;
          font-weight: 700;
          margin-block-end: 0;
          margin-block-start: 0;
          color: lighten($color-grey-base, 25%);
          text-align: right;
        }
      }
      .meter {
        background-color: $gray-bright;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 5px;
        span {
          width: 53%;
          background: $color-grey-base;
          height: 7px;
          display: block;
          background-image: linear-gradient(-45deg, transparent, transparent 40%, rgba(255, 255, 255, .25) 48%, rgba(255, 255, 255, .25) 52%, transparent 60%);
          background-size: 500px 14px;
          animation: 20s infinite linear progressbar;
        }
      }
    }

  }

  .project_bestand {
    .ribbon_text{
      background: $bestand-rgba;
    }
    .teaser--link .btn {
      background: $bestand;
    }
    .teaser--link .btn {
      border-color: $bestand;
    }
    .exporo-progress .meter span {
      background-color: $bestand;
    }
  }
  
  .project_finanzierung {
    .ribbon_text{
      background: $finanzierung-rgba;
    }
    .teaser--link .btn {
      background: $finanzierung;
    }
    .teaser--link .btn {
      border-color: $finanzierung;
    }
    .exporo-progress .meter span {
      background-color: $finanzierung;
    }
  }

}

.iframe-link{
  &:focus,
  &:hover{
    text-decoration: none !important;
  }
  .keyfacts{
    &__ratings {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;


      &--selected {
        &-AA .keyfacts__rating--aa,
        &-A .keyfacts__rating--a,
        &-B .keyfacts__rating--b,
        &-C .keyfacts__rating--c,
        &-D .keyfacts__rating--d,
        &-E .keyfacts__rating--e,
        &-F .keyfacts__rating--f {
          font-size: 1.6em;
        }
      }
    }
    &__rating {
      color: white;
      font-size: 0.8em;
      border-radius: 100%;
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 2px;
      font-weight: 400;
      @each $rating, $value in $investment-rating-map {
        &--#{$rating} {
          background-color: $value;
        }
      }
    }
  }
}

@keyframes progressbar {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 2000px 0;
  }
}
