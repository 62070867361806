.location {

    .wrap {
        padding-bottom: 75px;
    }

    .teaser {

        background: white;
        box-shadow: 0 0 8px rgba(0,0,0,.2);
        border-radius: 5px;
        color: $color-grey-base;
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        transition: all 0.4s;
        display: flex;
        @media screen and (max-width: $screen-xs-max) {
            flex-direction: column;
        }
        &--image{
            display: block;
            width: 50%;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            @media screen and (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
        &__tag {
            position: absolute;
            top: 0;
            text-align: center;
            width: calc(100% - 20px);
            background-color: rgba(255, 255, 255, 0.9);
            color: $brand-secondary;
            border-radius: 50px;
            margin: 10px;
            font-size: 13px;
            padding: 5px;
        }
        .icon-location {
            border-radius: 50px;
            background-color: $brand-secondary;
            color: white;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            top: 4px;
            left: 5px;
        }
        &__text {
            width: 50%;
            @media screen and (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
        &__kpis {
            display: flex;
            padding: 15px 20px 10px;
            border-bottom: 1px solid lighten($gray-light, 35);
            @media screen and (max-width: $screen-xs) {
                flex-direction: column;
            }
            &-item {
                width: 50%;
                @media screen and (max-width: $screen-xs) {
                    width: 100%;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 0;
                }
            }
            &-value {
                font-size: 20px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space: unset;
                max-height: 1.5em;
            }
            &-description {
                font-size: 13px;
            }
        }
        &__content {
            overflow: hidden;
            height: 65px;
            padding: 15px 18px 15px;
            font-size: 13px;
            position: relative;
            ul,
            p{
                margin: 0;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 20px;
                width: calc(100% - 36px);
                bottom: 0;
                background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
            }
        }
        &__more {
            background-color: $btn-default-bg;
            color: $gray;
            border-color: $gray;
            margin: 10px 20px 0;
            display: block;
            &:hover {
                background-color: $brand-secondary;
                border-color: $brand-secondary;
                color: $body-bg;
            }
            @media screen and (max-width: $screen-xs-max) {
                margin-bottom: 20px;
            }
        }
    }

}