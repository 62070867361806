.highchart {
  padding: 75px 0;
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $background;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 200px;
    left: 0;
    right: 0;
  }
  + .highchart{
    &:before{
      top: 0;
      height: 70%;
    }
  }
  &-subline {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &-chart {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 400px;
    margin: 0 auto;
    margin-top: 10px;
    width: 100%;
  }
  &-footnote {
    margin-top: 20px;
    margin-bottom: -10px;
    font-size: 14px;
  }
  &-contextbutton {
    display: none;
  }
  &-background {
    fill: none;
  }
}