@import 'https://code.highcharts.com/css/highcharts.css';
.container-block {
  padding: 75px 0;
  @media screen and (max-width: $screen-xs-max){
    padding: 55px 0;
  }
  .wrap {
    /*display flex*/
    & [class$="additional-block"], 
    & [class$="-content"] {
      padding: 20px;
    }
    &>div:not(:first-child) {
      padding-top: 30px;
    }
  }
  h4 {
    border-bottom: 2px solid $black;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 60px;
    padding-left: 15px;
    max-width: 100%;
    @media print {
      font-size: 16px;
    }
  }
  table {
    font-size: 15px;
    margin: 0;
    table-layout: fixed;
    width: 100% !important;
    @media print {
      font-size: 14px;
    }
    th {
      font-weight: bold;
      text-transform: uppercase;
      &:first-child {
        text-align: left;
      }
    }
    th ~ th, 
    td ~ td {
      text-align: right;
      white-space: nowrap;
      line-height: 3;
    }
    td, 
    th {
      padding: 0 10px;
    }
    tbody {
      tr:nth-child(odd) {
        background: $background3;
      }
    }
    &:nth-child(1) {
      thead {
        border-width: 2px 0 1px 0;
        border-color: $black;
        border-style: solid;
      }
    }
    &:nth-child(2), 
    &:nth-child(3) {
      thead {
        border-width: 2px 0 0 0;
        border-color: $black;
        border-style: solid;
      }
    }
    &:nth-child(3) {
      margin-top: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      thead {
        border-color: $brand-success;
        color: $brand-success;
      }
    }
    + p {
      margin-top: 15px;
    }
  }
  p {
    line-height: 1;
    margin: 0;
    + table {
      margin-top: 15px;
    }
    em {
      font-size: 14px;
    }
  }

  &--piechart-content {
    display: flex;
    align-items: center;
    @media screen and (max-width: $screen-md) {
      display: block;
    }
    .pie-chart__description {
      padding: 2em;
    }
  }

  .pie-chart__description, 
  .bullet-chart_description {
    font-size: 14px;
    ul {
      list-style: none;
      padding-left: 0;
      ul {
        padding-left: 20px;
      }
      >li {
        font-weight: bold;
        li {
          font-weight: normal;
        }
      }
    }
  }

  .pie-chart__container {
    height: 100% !important;
    max-width: 800px;
    min-width: 320px;
    margin: 0 auto;
  }

  svg {
    max-width: 100%;
  }

  .bullet-chart_container {
    display: flex;
    margin: 1em auto;
    &>div {
      color: white;
      font-size: 16px;
      hyphens: auto;
      padding: 1em;
      text-align: center;
      width: 50%;
    }
    .bullet-chart-value {
      font-weight: bold;
    }

    .highcharts-grid-line {
      stroke: none;
    }
  }

  .highcharts-plot-band-label {
    fill: $gray-base;
    .bullet-chart-value {
      font-weight: bold;
    }
    .bullet-chart-text {
      margin-top: 50px;
      @media screen and (max-width: $screen-xs) {
        display: none;
      }
    }
  }

  .highcharts-background {
    fill: transparent;
  }

  .highcharts-pie-series .highcharts-point {
    stroke: transparent;
    stroke-width: 2px;
  }

  .highcharts-container {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }

  &.colorful-theme {
    @for $num from 0 through 6 {
      .highcharts-color-#{$num} {
        fill: nth($investment-rating, ($num + 1));
        stroke: nth($investment-rating, ($num + 1));
      }
    }
    .highcharts-plot-band {
      fill-opacity: 1;
      &:nth-child(1) {
        fill: $investment-rating-1;
      }
      &:nth-child(2) {
        fill: $gray-base;
      }
    }
    .highcharts-plot-band-label:first-of-type {
      fill: $investment-rating-1;
    }
    .pie-chart__description, 
    .bullet-chart_description {
      ul {
        >li {
          &:first-child {
            color: $investment-rating-1;
          }
          li {
            &:first-child {
              color: $black;
            }
          }
        }
      }
      table tr:first-child {
        color: $exporo-theme-1;
      }
    }
    .bullet-chart_container {
      &>div {
        &:nth-child(1) {
          background: $investment-rating-1;
        }
        &:nth-child(2) {
          background: $investment-rating-2;
        }
      }
    }
  }

  &.exporo-theme {
    @for $num from 0 through 6 {
      .highcharts-color-#{$num} {
        fill: nth($exporo-theme, ($num + 1));
        stroke: nth($exporo-theme, ($num + 1));
      }
    }
    .highcharts-plot-band {
      fill-opacity: 1;
      &:nth-child(1) {
        fill: $exporo-theme-1;
      }
      &:nth-child(2) {
        fill: $gray-base;
      }
    }
    .highcharts-plot-band-label:first-of-type {
      fill: $exporo-theme-1;
    }
    .pie-chart__description, 
    .bullet-chart_description {
      ul {
        >li {
          &:first-child {
            color: $exporo-theme-1;
          }
          li {
            &:first-child {
              color: $black;
            }
          }
        }
      }
      table tr:first-child {
        color: $exporo-theme-1;
      }
    }
    .bullet-chart_container {
      &>div {
        &:nth-child(1) {
          background: $exporo-theme-1;
        }
        &:nth-child(2) {
          background: $exporo-theme-2;
        }
      }
    }
  }

  &.equity-theme {
    @for $num from 0 through 6 {
      .highcharts-color-#{$num} {
        fill: nth($equity-theme, ($num + 1));
        stroke: nth($equity-theme, ($num + 1));
      }
    }
    .highcharts-plot-band {
      fill-opacity: 1;
      &:nth-child(1) {
        fill: $equity-theme-1;
      }
      &:nth-child(2) {
        fill: $gray-base;
      }
    }
    .highcharts-plot-band-label:first-of-type {
      fill: $equity-theme-1;
    }
    .pie-chart__description, 
    .bullet-chart_description {
      ul {
        >li {
          &:first-child {
            color: $equity-theme-1;
          }
          li {
            &:first-child {
              color: $black;
            }
          }
        }
      }
      table tr:first-child {
        color: $equity-theme-1;
      }
    }
    .bullet-chart_container {
      &>div {
        &:nth-child(1) {
          background: $equity-theme-1;
        }
        &:nth-child(2) {
          background: $equity-theme-2;
        }
      }
    }
  }

  .highcharts-pie-series {
    .highcharts-data-label-connector {
      fill: transparent;
      stroke: silver;
      stroke-dasharray: 2, 2;
      stroke-width: 2px;
    }
  }

  .highcharts-title {
    text-transform: uppercase;
    .pie-chart-title {
      fill: $gray-middle;
      font-weight: bold;
      font-size: 30px;
      @media screen and (max-width: $screen-md) {
        font-size: 20px;
      }
    }
    .pie-chart-subline {
      font-size: 16px;
      word-break: break-word;
      max-width: 50px;
      @media screen and (max-width: $screen-md) {
        font-size: 13px;
      }
    }
  }


  @media screen and (max-width: $screen-md) {
    .wrap {
      display: block;
      padding: 0;
    }
    &__left, 
    &__right {
      float: left;
      width: 100%;
    }
  }
}

.investment-rating {
  + .container-block {
    margin-top: 50px;
  }
}
