.team {
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 130px;
    left: 0;
    right: 0;
  }
  .teaser{
    background: white;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    border-radius: 5px;
    color: $color-grey-base;
    height: 370px;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    a{
      text-decoration: none;
    }
    &--headline{
      font-size: 17px;
      line-height: 22px;
      margin: 0;
      max-width: 80%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      @media screen and (max-width: $screen-xs-max){
        font-size: 5vw;
      }
    }
    &--subline{
      margin-top: 6px;
      color: $color-grey-base;
      font-size: 11px;
      font-weight: 700;
    }

    &--summary{
      color: $color-grey-base;
      font-size:14px;
      margin-top:6px;
      padding-right: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      p{
        margin:0;
      }
      ul{
        margin: 0;
        padding:0;
        li{
          display: inline;
          list-style: none;
        }
      }
    }
    &--image{
      transition: all 0.4s;
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        min-height: 100%;
        height: auto;
        transition: all 0.4s;
      }
      + .blog-teaser--container{
        margin-top: 0.4em;
      }
    }
    &--content{
      padding: 15px 15px;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
    }
    &--container,
    &--container{
      overflow: hidden;
    }
    &--tags{
      height: 200px;
    }
    &--image{
      height: 320px;
    }
  }
  &.press__img_fix{
    img{
      width: 162%;
      margin-left: -29%;
      margin-top: -38%;
      min-height: 100%;
      height: auto;
      transition: all 0.4s;
    }
  }
}
