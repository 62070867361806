.dropdown {
  padding: 0 0 50px;
  position: relative;
  &__content {
    margin: 25px 0;
    padding: 5px 0;
    &>*{
      padding: 0 25px;
    }
    &-text {
      cursor: pointer;
      display: block;
      list-style: none;
      padding: 20px 25px;
      position: relative;
      ~ .faq__content-text{
        border-top: 0;
      }
    }
    label{
      font-size: 18px;
      display: block;
      line-height: 1;
      padding-right: 30px;
      width: 100%;
      &:after{
        padding: 0.4em;
      }
    }
    p{
      margin-left: 0;
    }
  }
  .accordion{
    label{
      text-align: center;
      transition: all 0.2s ease-out;
      line-height: 1;
      &:before{
        position: relative;
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        -webkit-animation: fade_move_down 3s ease-in-out infinite;
        -moz-animation:    fade_move_down 3s ease-in-out infinite;
        animation:         fade_move_down 3s ease-in-out infinite;
      }
      &:hover{
        color: $color-grey-base;
        opacity: 0.8;
      }
    }
    input:checked ~ .accordion-content{
      max-height: 300em;
    }
  }
  &-text-area{
    ol,
    ul {
      padding: 0 10px 0 15px;
      margin: 30px 0;
      li {
        align-items: center;
        font-size: 16px;
        margin-left: 0;
        list-style: none;
        margin-bottom: 40px;
        padding-left: 10px;
        position: relative;
        &:before {
          content: '\25CF';
          color: $gray-solid-2;
          font-size: 20px;
          display: inline-block;
          left: -15px;
          margin-top: -3px;
          padding-right: 20px;
          position: absolute;
        }
      }
    }
  }

}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-3px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,3px); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-3px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,3px); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-3px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,3px); opacity: 0; }
}