$brand-primary: #5774BA;
$brand-secondary: #274a7f;
$brand-secondary_rgb: 39 74 127;
$brand-tertiary: #D3E6F9;
$brand-success: #82bc24;
$brand-info: #f0ad4e;
$brand-warning: #f0ad4e;
$brand-danger: #f0ad4e;


$gray-base: #565656;
$gray-bright: #ededf3;
$gray-solid: #c7c7c7;
$gray-solid-2: #d8d8d8;
$gray-middle: #91908f;
$black: #333333;

$background: #f9f9f9;
$background2: $brand-secondary;
$background3: $background;

$light-blue: #b6c1cf;
$dark-light-blue: #a5afbd;

$color-grey-base: #626262;

$investment-rating-1: #b6c1cf;
$investment-rating-2: $brand-secondary;
$investment-rating-3: #1e73be;
$investment-rating-4: #82bc24;
$investment-rating-5: #c1da00;
$investment-rating-6: #fae100;
$investment-rating-7: #fba609;
$investment-rating: $investment-rating-1,$investment-rating-2,$investment-rating-3,$investment-rating-4,$investment-rating-5,$investment-rating-6,$investment-rating-7;
$investment-rating-map: (
    aa: $investment-rating-1,
    a: $investment-rating-2,
    b: $investment-rating-3,
    c: $investment-rating-4,
    d: $investment-rating-5,
    e: $investment-rating-6,
    f: $investment-rating-7,
);

$maps-marker: #23497f;
$block-container: #555554;
$footer-background: #b8c1ce;


$exporo-theme-1: $brand-primary;
$exporo-theme-2: $brand-secondary;
$exporo-theme-3: #5fa8d5;
$exporo-theme-4: #ededf3;
$exporo-theme-5: #9099ba;
$exporo-theme-6: #6d73a0;
$exporo-theme-7: #99D4F1;
$exporo-theme: $exporo-theme-1,$exporo-theme-2,$exporo-theme-3,$exporo-theme-4,$exporo-theme-5,$exporo-theme-6,$exporo-theme-7;

$equity-theme-1: #82bb21;
$equity-theme-2: #c1da00;
$equity-theme-3: #66A402;
$equity-theme-4: #dbe9be;
$equity-theme-5: #53b200;
$equity-theme-6: #82bb21;
$equity-theme-7: #c1da00;
$equity-theme-8: #66A402;
$equity-theme: $equity-theme-1,$equity-theme-2,$equity-theme-3,$equity-theme-4,$equity-theme-5,$equity-theme-6,$equity-theme-7,$equity-theme-8;


/* PRODUCT COLOR PALETTE */
$bestand: #86AC48;
$bestand-rgba: rgba(171,202,121, 0.9);
$finanzierung: #3968AF;
$finanzierung-rgba: rgba(66,106,168, 0.8);
