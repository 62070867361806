.list-items {
  padding-bottom: 50px;
  ul {
    list-style: none;
    max-width: 750px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    font-size: 16px;
    justify-content: center;
  }
  li {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;
    padding: 0 20px 40px;
    font-weight: 700;
    line-height: 1.5;
    span {
      color: $color-grey-base;
    }
    @media screen and (max-width: $screen-xs) {
      flex-basis: 100%;
    }
  }
  li:before {
    content: '\e810';
    font-family: "fontello";
    color: inherit;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;
  }
  &.bestand{
    .list-items__list{
      color: $bestand;
    }
  }
  &.finanzierung{
    .list-items__list{
      color: $finanzierung;
    }
  }
}