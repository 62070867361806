$stage-height: 800px;

.stage {
  overflow: hidden;
  position: relative;
  .btn {

    &.primary {
      background: #264A7E;
      color: white;
    }
    &.primary::after {
      color: white;
    }
    &.secondary::after {
      color: #264A7E;
      content: "\e801";
      font-size: 18px;
      top: 12px;
    }
  }
  &__image {
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /*@media screen and (max-width: $screen-xs) {*/
    @supports (-webkit-overflow-scrolling: touch) {
      background-attachment: scroll !important;
      /* fix for IOS */
    }
    .product &{
      @media screen and (max-width: $screen-md) {
        height: 40em;
      }
    }
  }
  &:before {
    content: '';
    background: linear-gradient(to bottom, rgba(90, 91, 92, 0.5), transparent);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &.gallery-stage{
    &:before{
      background: none;
    }
    .carousel__track .carousel__slide{
      &:before {
        content: '';
        background: linear-gradient(to bottom, rgba(90, 91, 92, 0.5), transparent);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }
  &>div {
    text-align: left;
  }
  &__content {
    margin: 0 auto;
    max-width: 1080px;
    min-height: 100vh;
    padding: 280px 0 200px 100px;
    position: relative;
    text-align: center;
    z-index: 20;
    @media screen and (max-width: $screen-md) {
      padding: 30vh 20px 25vh;
      .gallery-stage &{
        padding: 15vh 10vh 0 10vh;
      }
    }
    .product &{
      padding: 200px 0 100px 100px;
      min-height: 100%;
      &:after{
        content: "";
        float: none;
        display: table;
      }
      .stage{
        &__headline{
          font-size: 60px;
          line-height: 1;
          margin-bottom: 7px;
          max-width: 100%;
          @media screen and (max-width: $screen-md) {
            font-size: 8.9vw;
          }
        }
        &__subline{
          margin-bottom: 2em;
        }
        &__button{
          color: white;
          cursor: pointer;
          display: inline-block;
          margin-right:1em;
        }
        &__contentbox{
          float: left;
          padding: 7em 1em 7em 0;
          width: 55%;
          @media screen and (max-width: $screen-md) {
            padding: 3em 2em 1em 0;
          }
        }
        &__keyfacts {
          background-color: rgba(255,255,255,0.9);
          border-radius: 5px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
          float:right;
          width: 350px;
          small{
            font-size: 70%;
          }
          &__title{
            border-radius: 5px 5px 0 0;
            color: white;
            font-size: 16px;
            padding: 0.5em;
            text-align: center;
            text-transform: uppercase;
            background: $brand-secondary;
          }
          &__content{
            &-highlights{
              padding: 1em 1em 0.5em 1em;
              &>div{
                display: inline-block;
                width: 49%;
                padding: 0 0.5em;
                text-align: center;
                vertical-align: top;
              }
              small:first-child{
                min-height: 1.3em;
              }
              small,
              strong{
                display: block;
                text-transform: uppercase;
              }
              strong{
                font-size: 20px;
                + small{
                  line-height: 1.2;
                  margin-top: 5px;
                  text-transform: unset;
                }
              }
              .left{
                border-right: 1px solid $gray-solid;
              }
            }
            ul{
              margin: 0;
              list-style: none;
              padding: 0;
              li{
                border-bottom: 1px solid $gray-solid;
                display: flex;
                padding: 0.5em 1em;
                span{
                  font-size: 12px;
                  &:last-child{
                    flex: 1;
                    text-align: right;
                  }
                  &.value{
                    font-weight: bold;
                  }
                }
              }
            }
          }
          .exporo-progress{
            padding: calc(0.5em - 10px) calc(1em - 10px);
          }
          &__ratings {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;

            &--selected {
              &-AA .stage__keyfacts__rating--aa,
              &-A .stage__keyfacts__rating--a,
              &-B .stage__keyfacts__rating--b,
              &-C .stage__keyfacts__rating--c,
              &-D .stage__keyfacts__rating--d,
              &-E .stage__keyfacts__rating--e,
              &-F .stage__keyfacts__rating--f {
                font-size: 1.6em;
              }
            }
          }
          &__rating {
            color: white;
            font-size: 0.8em;
            border-radius: 100%;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 2px;
            font-weight: 400;
            &--link{
              display: block;
              transition: all 0.5s ease-out;
              &:focus,
              &:hover{
                opacity: 0.8;
                text-decoration: none;
                &>*{
                  text-decoration: none;
                }
              }
            }
            @each $rating, $value in $investment-rating-map {
              &--#{$rating} {
                background-color: $value;
              }
            }
          }

          &__button{
            text-align: center;
            padding: 1em;
            padding-top: calc(1em - 10px);
            .btn{
              color: white;
              background: $brand-secondary;
              margin-top: 0;
              padding: 10px 20px;
              width: 100%;
              text-align: center;
              &:after{
                content: "";
                width: 0;
              }
            }
          }
          &.bestand{
            .stage__keyfacts__title,
            .stage__keyfacts__button .btn{
              background: $bestand;
              &.coming-soon{
                background: $brand-info;
              }
              &.funded{
                background: $gray-middle;
                cursor: not-allowed;
              }
            }
          }
          &.finanzierung{
            .stage__keyfacts__title,
            .stage__keyfacts__button .btn{
              background: $finanzierung;
              &.coming-soon{
                background: $brand-info;
              }
              &.funded{
                background: $gray-middle;
                cursor: not-allowed;
              }
            }
          }
        }
      }
      @media screen and (max-width: $screen-md) {
        display: block;
        padding: 10vh 20px 1vh;
        overflow: auto;
        min-height: auto;
        .stage__contentbox,
        .stage__keyfacts{
          display: block;
          float: none;
          width: 100%;
        }
      }
    }
  }

  &__headline {
    @extend .headline;
    color: white;
    font-size: 95px;
    font-weight: 400;
    line-height: 98px;
    letter-spacing: -2px;
    margin: 0;
    margin-bottom: 15px;
    max-width: 95%;
    position: relative;
    text-align: left;
    @media screen and (max-width: $screen-md) {
      font-size: 40px;
      line-height: 70px;
      max-width: 100%;
      word-break: break-word;
    }
    @media screen and (max-width: $screen-xs) {
      font-size: 8.9vw;
      line-height: 35px;
      letter-spacing: -1px;
      max-width: 90%;
      margin-bottom: 10px;
      word-break: break-word;
    }
  }

  &__subline {
    color: white;
    text-align: left;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    max-width: 60%;
    text-shadow: 3px 2px 15px rgba(0, 0, 0, 0.2);
    .product &{
      max-width: 100%;
    }
    @media screen and (max-width: $screen-md) {
      font-size: 20px;
      max-width: 100%;
    }
    @media screen and (max-width: $screen-xs) {
      line-height: 23px;
      font-size: 18px;
    }
  }
  .btn{
    background: white;
    color: $color-grey-base;
    font-size: 12px;
    margin-top: 55px;
    margin-right: 20px;
    line-height: 21px;
    padding: 10px 50px 10px 30px;
    position: relative;
    text-align: left;
    @media screen and (max-width: $screen-xs) {
      margin-top: 45px;
      margin-bottom: 10px;
      &.secondary {
        margin-top: 10px;
      }
    }
    ~.btn{
      margin-left: 20px;
      margin-top: 15px;
      @media screen and (max-width: $screen-xs) {
        margin-left: 0;
      }
    }
    &:after{
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      font-size:20px;
      box-shadow: none;
      color: $brand-secondary;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 2em;
      margin-right: 0;
      text-align: center;
      line-height: 1em;
      margin-left: .5em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e803';
      text-shadow: none;
      position: absolute;
      right: 0;
      top: 25%;
    }
  }

  &__link {
    margin-top: 40px;
    max-width: 70%;
    @media screen and (max-width: $screen-md) {
      line-height: 1.2;
      max-width: 100%;
    }
  }

  &__image {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .stage__scrolldown {
    position: absolute;
    bottom: 0;
    height: 130px;
    width: 145px;
    left: auto;
    right: -13vw;
    #scrolldown-circle {
      animation: bounce 1s infinite alternate;
    }
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &.stage--slim {
    &:before {
      background-color: rgba(0, 0, 0, .5);
    }
    .stage__content {
      min-height: 50vh;
      padding: 180px 60px 120px 60px;
    }
    .stage__headline {
      font-size: 60px;
      margin-bottom: 5px;
    }
    .stage__subline {
      max-width: 100%;
    }

    @media screen and (max-width: $screen-xs) {
      &:before {
        background-color: rgba(0, 0, 0, .65);
      }
      .stage__content {
        padding: 120px 30px 80px 30px;
      }
      .stage__headline {
        font-size: 32px;
      }
      .stage__subline {
        font-size: 14px;
      }
    }
  }

  #open-video,
  #open-gallery{
    color: white;
    cursor: pointer;
    display: inline-block;
    margin-right:1em;
    &:before{
      font-family: fontello;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      box-shadow: none;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 2em;
      text-align: center;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: none;
      content: '\e801';
      border: 1px solid white;
      border-radius: 100%;
      padding: 0.4em 0.3em 0.4em 0.6em;
      margin-right: 5px;
    }
    &:hover,
    &:focus{
      text-decoration: none;
    }
    @media screen and (max-width: $screen-md) {
      display: block;
      margin-bottom: 15px;
    }
  }
  #open-gallery{
    &:before{
      content:'\e82D';
      padding: 0.4em 1.4em 0.4em 0.5em;
    }
  }

  #stage-video,
  #stage-gallery{
    margin: 0 auto;
    left: 17%;
    display:none;
    position: fixed;
    top: 7vh;
    width: 70%;
    z-index: 1000001;
    &:target,
    &:target ~ #stage-mask{
      display: block;
    }
    @media screen and (max-width: $screen-md) {
      left: 0;
      width: 100%;
    }

  }
  .gallery__content{
    .carousel{
      @media screen and (max-width: $screen-md) {
        display: none;
      }
    }
    .gallery-stage.mobile{
      display: none;
      @media screen and (max-width: $screen-md) {
        display:block;
        height: 100%;
        cursor: grab;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        flex-wrap: unset;
        justify-content: unset;
        img{
          flex: 0 0 45px;
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
.stage__subheader{
  /*display: none;*/
  background-color: rgba(255, 255, 255, 0);
  height: 0;
  transition: 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  @media screen and (max-width: $screen-md) {
    white-space: nowrap;
    -ms-overflow-style: none;
    overflow-x: scroll;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &.subheader__fix{
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    height: 3em;
    /*display: block;*/
    top: 60px;
    z-index: 30;
    .underbar {
      width: 0;
      height: 3px;
      background: rgba(100, 100, 200, 0);
      top: calc(100% - 3px);
      position: absolute;
      -webkit-transition: 0.5s ease;
    }
    a{
      display: inline-block;
      font-size: 16px;
      padding-right: 2em;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      vertical-align: top;
      -webkit-transition: 0.5s ease;
      cursor: pointer;
      position: relative;
      &:after{
        content: "";
        display: block;
        position: absolute;
        right: 0;
        height: 3px;
        width:0;
        transition: width 0.4s ease-out;
        background: white;
        top: calc(100% - 3px);
      }
      &:hover,
      &:active,
      &.active,
      &:focus{
        text-decoration: none;
        &:after{
          width: calc(100% - 2em);
          right: auto;
          left: 0;
        }
      }
    }
    &.right{
      a{
        &:after{
          right: auto;
          left: 0;
        }
        &:hover,
        &:active,
        &.active,
        &:focus{
          &:after{
            width: 100%;
            right: 0;
            left: auto;
          }
        }
      }
    }
    &.bestand,
    &.finanzierung{
      background: $bestand;
      opacity: 0.9;
      a{
        color: white;
      }
    }
    &.finanzierung{
      background: $finanzierung;
    }
  }
}
.close{
  display: block;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 1%;
  right: 1%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-decoration: none;
  text-align: center;
  color: white;
  font-weight: 700;
  border: 1px solid white;
  border-radius: 100%;
  z-index: 1;
  &:before{
    font-family: fontello;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    box-shadow: none;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    line-height: 30px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: none;
    content: '\e81e';
    transition: all 0.15s;
    vertical-align: super;
  }
  &:hover{
    &:before{
      color: white;
      transform: rotate(180deg);
    }
  }
  @media screen and (max-width: $screen-md) {
    right: 5px;
    top: -35px;
  }
}

.scroll-offset{
  padding-top: 150px !important;
  transition: all 0.4s;
  .table-block__headline{
    padding-top: 0;
  }
}
#subheader-container{
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
  height: 100%;
  line-height: 3em;
  padding-left: 25px;
  position: relative;
  @media screen and (min-width: $screen-md) {
    overflow: hidden;
  }
}
#stage-mask{
  background:rgba(0,0,0,0.5);
  display:none;
  left:0;
  height:100%;
  position:fixed;
  top:0;
  transition: all 0.4s;
  width:100%;
  z-index: 999999;
}
.bestand .btn,
.btn[href*="bestand"]{
  background: white;
  color: $color-grey-base;
  &:after{
    color: $bestand;
  }

}
.finanzierung .btn,
.btn[href*="finanzierung"]{
  background: white;
  color: $color-grey-base;
  &:after{
    color: $finanzierung;
  }
}

@-webkit-keyframes grow {
  0%  {
        transform: scale(1);
  }
  50%  {
        transform: scale(1.1);
  }
}

@-moz-keyframes grow {
  0%  {
        transform: scale(1);
  }
  50%  {
        transform: scale(1.1);
  }
}

@-ms-keyframes grow {
  0%  {
        transform: scale(1);
  }
  50%  {
        transform: scale(1.1);
  }
}

@-o-keyframes grow {
  0%  {
        transform: scale(1);
  }
  50%  {
        transform: scale(1.1);
  }
}

@keyframes grow {
  0%  {
        transform: scale(1);
  }
  50%  {
        transform: scale(1.1);
  }
}
#wistia-rz2thsodqy-1_popover{
  z-index: 1000000 !important;
}
#wistia-rz2thsodqy-1_popover_popover_close_button {
  z-index: 1000001 !important;
}
