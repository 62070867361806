.social-media-buttons {
  font-size: 30px;
  text-align: left;
  position: absolute;
  left: 30px;
  top: 0;
  @media screen and (max-width: $screen-xs-max) {
    left: auto;
    top: auto;
    margin: 0 auto 50px;
    position: relative;
  }
  a {
    display: block;
    padding-right: 5px;
    border: none;
    @media screen and (max-width: $screen-xs-max) {
      display: inline-block;
    }
    &:hover, 
    &:focus {
      text-decoration: none;
    }
    [class^="icon-"] {
      &:before {
        font-size:20px;
        color: $color-grey-base;
        opacity: 0.8;
        transition: all 0.2s ease-out;
      }
      &:hover {
        &:before {
          background: white;
          color: $brand-secondary;
          transform: scale(1.2);
        }
      }
    }
  }
}
