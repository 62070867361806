.image-text {
  background-color: $background;
  padding: 75px 0;
  @media screen and (max-width: $screen-xs-max){
    padding: 55px 0;
  }

  ul {
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      margin-left: 0;
      list-style: none;
      line-height: 40px;
      &:before {
        content: '\e810';
        font-size: 17px;
        font-family: 'fontello';
        color: $brand-primary;
        speak: none;
        font-style: normal;
        font-weight: bold;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        padding-right: 10px;
      }
    }
  }
  table {
    @media print {
      margin: 20px 0;
    }
  }
  &>div {
    display: flex;
    align-items: flex-start;
    @media print {
      display: block;
      width: 100%;
    }
    @media screen and (max-width: $screen-md) {
      flex-direction: column;
      width: 100%;
    }
  }

  img {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    &[src$=".png"],
    &[src*=".png"]{
      box-shadow:none;
      padding-top: 30px;
      @media screen and (max-width: $screen-md) {
        padding-top: 0;
      }
    }
  }

  &__background-white {
    background-color: white;
  }

  &__background-blue {
    background-color: $background2;
    color: white;
    .image-text__headline {
      color: white;
      &>* {
        color: white;
      }
    }
  }
  &__background-green {
    background-color: #86AC48;
    color: white;
    .image-text__headline {
      color: white;
      &>* {
        color: white;
      }
    }
  }

  &__imagecolor-black-white {
    .image-text__item {
      filter: grayscale(100%);
      transition: all 0.5s ease;
      &:hover {
        filter: none;
      }
    }
    .image-text__item--text {
      filter: none;
    }
    img {
      box-shadow: none;
    }
  }

  h2,
  &__headline {
    @extend .headline;
    margin-bottom: 10px;
  }

  &__cta {
    text-align: left;
    margin-top: 30px;
    @media print {
      font-size: 23px;
      margin-top: 10px;
    }
    @media screen and (max-width: $screen-md) {
      margin-top: 0.8em;
      a {
        font-size: 0.8em;
      }
    }
    .btn {
      border: 1px solid white;
    }
  }

  &__border-with {
    padding: 75px 0;
    @media screen and (max-width: $screen-xs-max) {
      padding: 2em 1em;
    }
  }

  &__item {
    vertical-align: top;
    display: inline-block;
    width:45%;
    @media screen and (max-width: $screen-xs-max) {
      width: 100%;
      &.image-text__item--image{
        order: 1;
        margin-bottom: 2em;
      }
      &.image-text__item--text{
        order: 2;
      }
    }
    &--text {
      text-align: left;
      width:55%;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        max-width: 100%;
        text-align: left;
      }
      @media screen and (max-width: $screen-md) {
        padding: 0;
        width:100%;
      }
      p {
        margin: 0;
        padding-bottom: 1em;
        ~ p{
          margin-bottom: 0;
        }
      }
      table {
        td,
        th {
          padding: 0 5px;
          ~ td,
          ~ th {
            word-break: break-word;
          }
        }
        @media screen and (max-width: $screen-xs-max) {
          margin-left: -22px;
        }
      }
      + .image-text__item--image {
        padding-left: 20px;
        @media screen and (max-width: $screen-xs-max) {
          padding-left: 0;
        }
      }
    }
    + .image-text__item--text {
      padding-left: 20px;
      @media screen and (max-width: $screen-xs-max) {
        padding-left: 0;
      }
    }
  }
}
