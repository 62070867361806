@page {
  margin: 0;
  size: 8.5in 11in;
  position: relative;
  counter-reset: page;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  body {
    font-size: 12px;
  }
  main {
    margin: 0;
  }
  .content-block, 
  .container-block {
    page-break-before: always;
    margin-top: 50px;
    padding-top: 0 !important;
  }
  .faq, 
  .investment-rating, 
  .print_footer {
    page-break-before: always;
  }
  .stage__link, 
  .mapboxgl-control-container, 
  .cta__btn, 
  .btn, 
  .hide-in-pdf {
    display: none !important;
  }
  .faq__content-text__body {
    max-height: 50em !important;
  }
  .faq__content-text__head {
    background: $black !important;
    color: white !important;
  }
  .investment-rating, 
  .gallery {
    background: white !important;
    color: $black !important;
    .gallery__headline {
      color: $black !important;
    }
  }
  .container-block__left, 
  .container-block__right, 
  .investment-rating__content, 
  .faq__content-text__body {
    background: white !important;
  }
}
