body {
  background-color: white;
  &:not(.firstStage){
    main{
      margin-top:200px;
      &.plain{
        margin-top: 0;
      }
      @media screen and (max-width: $screen-xs){
        margin-top: 0;
        padding-top: 60px;
        &.plain{
          padding-top: 0;
        }
      }
    }
  }
}
a{
  @media screen and (max-width: $screen-xs){
    word-wrap: break-word;
  }
}
.raw-html{
  max-width: 100%;
  iframe{
    max-width: 100vw;
    min-height: auto;
  }
}

main {
  width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: $screen-md) {
    margin-top: 0;
  }
}

.embeddedServiceHelpButton .helpButton {
  right: initial !important;
}

.exporo-not-scrollable {
  main {
    position: relative;
  }
  .embeddedServiceHelpButton,
  .embeddedServiceSidebar {
    display: none;
  }

  .ub-emb-bar-frame {
    bottom: -140px !important;
  }
}

img.lazy {
  opacity: 0;
  animation: opacity 100ms;
}

img.lazy.complete {
  opacity: 1;
}

.container {
  margin: 0 auto;
  width: auto;
  max-width: 1080px;
}

section {
  position: relative;
}

.wrap {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 25px;
}

[ui-view] {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-out;
}

#scroll-top-button{
  opacity: 0;
  font-size: 27px;
  bottom: 1em;
  left: 5%;
  position: fixed;
  transition: 0.5s ease-in-out;
  transform: rotate(-90deg);
  &:after{
    content: "";
    background: white;
    display: block;
    width: 40%;
    height: 40%;
    position: absolute;
    left: 11px;
    top: 12px;
    z-index: -1;
  }
}


.notfound{
  h1,
  h2{
    font-size: 32px;
    max-width: 100%;
    width: 100%;
    text-align: center;
    strong{
      color: $brand-secondary;
    }
    @media screen and (max-width: $screen-xs){
      font-size: 26px;
      line-height: 1.3;
    }
  }
  h1{
    font-size: 120px;
    line-height: 1;
    margin-bottom: 15px;
    span{
      &:nth-child(2){
        color: $brand-primary;
      }
      &:nth-child(3){
        color: $brand-tertiary;
      }
    }
  }
  .btn-container{
    text-align: center;
    margin-bottom: 1em;
    padding: 1em 0;
  }
  .wrap{
    background: $background;
    padding: 3em;
    border-radius: 5em;
    margin-bottom: 5em;
  }
}


.exporo-spinner-page {
  width: 300px;
  height: 300px;
  max-width: 100%;
  margin: 5% auto /*default, if vh fails */;
  margin: 5vh auto;
  display: block;
  @media screen and (max-width: $screen-xs){
    width: 150px;
    height: 150px;
    margin: 3vh auto;
  }
  &:before {
    content:"";
    display: block;
    width: 300px;
    height: 300px;
    border-radius: 300px;
    animation: load8 1s infinite linear;
    position: absolute;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;

  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.exporo-spinner-page .path {
  stroke-width: 0.3;
  fill-opacity: 0;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  -webkit-animation-name: dashIn;
  animation-name: dashIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.alternate .path {
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.even .path {
  -webkit-animation-direction: normal;
  animation-direction: normal;
}
.even .path:nth-child(even) {
  animation-direction: reverse;
}
.mix .path {
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.mix .path:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.mix .path:nth-child(3) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(4) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.mix .path:nth-child(5) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(6) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.mix .path:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(8) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.mix .path:nth-child(9) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(10) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.mix .path:nth-child(11) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(12) {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.mix .path:nth-child(13) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.mix .path:nth-child(14) {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.mix .path:nth-child(15) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@-webkit-keyframes dashIn {
  0% {
    stroke-dashoffset: 100;
    fill-opacity: 0;
    stroke-width: 0.3;
  }
  15% {
    stroke-dashoffset: 100;
    fill-opacity: 0;
  }
  20% {
    fill-opacity: 0;
  }
  30% {
    fill-opacity: 0;
  }
  40% {
    fill-opacity: 0.6;
  }
  60% {
    fill-opacity: 0.8;
    stroke-width: 0;
  }
  80% {
    fill-opacity: 0.9;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
    stroke-width: 0;
  }
}
@keyframes dashIn {
  0% {
    stroke-dashoffset: 100;
    fill-opacity: 0;
    stroke-width: 0.3;
  }
  15% {
    stroke-dashoffset: 100;
    fill-opacity: 0;
  }
  20% {
    fill-opacity: 0;
  }
  30% {
    fill-opacity: 0;
  }
  40% {
    fill-opacity: 0.6;
  }
  60% {
    fill-opacity: 0.8;
    stroke-width: 0;
  }
  80% {
    fill-opacity: 0.9;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
    stroke-width: 0;
  }
}

.plain{
  section{
    &:first-child{
      padding-top: 0;
    }
  }
}
