$container-sm: auto;
$container-md: auto;
$grid-gutter-width: 10px;
$alert-border-radius: 2px;
//
//
@import '/fonts/fontello/css/fontello.css';

@import './bootstrap/mixins/_alerts.scss';
@import './bootstrap/mixins/_background-variant.scss';
@import './bootstrap/mixins/_border-radius.scss';
@import './bootstrap/mixins/_buttons.scss';
@import './bootstrap/mixins/_center-block.scss';
@import './bootstrap/mixins/_clearfix.scss';
@import './bootstrap/mixins/_forms.scss';
@import './bootstrap/mixins/_gradients.scss';
@import './bootstrap/mixins/_grid-framework.scss';
@import './bootstrap/mixins/_grid.scss';
@import './bootstrap/mixins/_hide-text.scss';
@import './bootstrap/mixins/_image.scss';
@import './bootstrap/mixins/_labels.scss';
@import './bootstrap/mixins/_list-group.scss';
@import './bootstrap/mixins/_nav-divider.scss';
@import './bootstrap/mixins/_nav-vertical-align.scss';
@import './bootstrap/mixins/_opacity.scss';
@import './bootstrap/mixins/_pagination.scss';
@import './bootstrap/mixins/_panels.scss';
@import './bootstrap/mixins/_progress-bar.scss';
@import './bootstrap/mixins/_reset-filter.scss';
@import './bootstrap/mixins/_reset-text.scss';
@import './bootstrap/mixins/_resize.scss';
@import './bootstrap/mixins/_responsive-visibility.scss';
@import './bootstrap/mixins/_size.scss';
@import './bootstrap/mixins/_tab-focus.scss';
@import './bootstrap/mixins/_table-row.scss';
@import './bootstrap/mixins/_text-emphasis.scss';
@import './bootstrap/mixins/_text-overflow.scss';
@import './bootstrap/mixins/_vendor-prefixes.scss';
@import './bootstrap/normalize';
@import './bootstrap/variables';
@import './bootstrap/scaffolding';
@import './bootstrap/utilities';
@import './bootstrap/grid';
@import './bootstrap/responsive-utilities';

@import './base/variables';
@import './base/text';
@import './base/animation.scss';
@import './base/accordion.scss';
@import './base/arrow.scss';
@import './base/buttons.scss';
@import './base/content.scss';
@import './base/footer.scss';
@import './base/header-mobile-burger.scss';
@import './base/header-partner.scss';
@import './base/header.scss';
@import './base/breadcrumb.scss';
@import './base/investment-rating.scss';
@import './base/logo-stage.scss';
@import './base/masonry.scss';
@import './base/modal.scss';
@import './base/page--kpis.scss';
@import './base/page--stage.scss';
@import './base/paginator.scss';
@import './base/print.scss';
@import './base/social-media.scss';
@import './base/table-block.scss';
@import './base/variables.scss';
@import './base/blog_categories.scss';

@import './base/partials/blog.scss';


@import './grid/anchor.scss';
@import './grid/benefits.scss';
@import './grid/carousel-gallery.scss';
@import './grid/container_block.scss';
@import './grid/content_block.scss';
@import './grid/project.scss';
@import './grid/related_articles.scss';
@import './grid/cta.scss';
@import './grid/default-text.scss';
@import './grid/dropdown.scss';
@import './grid/quotes.scss';
@import './grid/faq.scss';
@import './grid/image_text.scss';
@import './grid/image_view.scss';
@import './grid/gallery.scss';
@import './grid/headline-subline.scss';
@import './grid/highchart.scss';
@import './grid/kpis.scss';
@import './grid/legal.scss';
@import './grid/maps.scss';
@import './grid/products.scss';
@import './grid/stage.scss';
@import './grid/usp.scss';
@import './grid/video.scss';
@import './grid/projects.scss';
@import './grid/list_items.scss';
@import './grid/video_quote.scss';
@import './grid/infobar.scss';
@import './grid/closed-projects.scss';

@import './teaser/blog.scss';
@import './teaser/glossar.scss';
@import './teaser/locations';
@import './teaser/network';
@import './teaser/team';
