.headline-subline {
  position: relative;
  z-index: 20;
  text-align: center;
  max-width: 1080px;
  margin: 0 auto;
}

.headline-subline--content {
  position: relative;
  margin: 0 auto;
}

.headline-subline--headline {
  @extend .headline;
  font-size: 95px;
  line-height: 98px;
  margin-bottom: 60px;
  @media screen and (max-width: $screen-xs-max) {
    word-break: break-word;
  }
}

.headline-subline--subline {
  @extend .subline;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: $screen-xs-max) {
    word-break: break-word;
  }
}

.headline-subline--description {
  text-align: left;
}