.investment-rating {
  padding: 75px 0;
  position: relative;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }

  &__headline {
    h3 {
      @extend .headline;
      display: inline-block;
      padding-right: 25px;
      vertical-align: -webkit-baseline-middle;
      vertical-align: -moz-middle-with-baseline;
      @media (max-width: $screen-md) {
        display: block;
      }
    }
  }

  &__result {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    display: inline;
    @media (max-width: $screen-md) {
      display: block;
      margin-top: 20px;
    }

    &-icons {
      border-radius: 100%;
      background: $background;
      color: white;
      display: inline-block;
      height: 2em;
      line-height: 2em;
      margin-left: 2%;
      text-transform: uppercase;
      vertical-align: middle;
      width: 2em;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__content {
    background: $background;
    ~ .investment-rating__text{
      margin-top: 35px;
    }
  }

  &__description {
    display: block;
    font-size: 70%;
    line-height: 1.5;
    text-align: left;
    padding: 30px 0;
    @media print {
      font-size: 80%;
      line-height: 1.3;
    }
  }
  &__text{
    margin-bottom: 35px;
    h4{
      font-size:25px;
    }
  }

  @for $i from 1 through length($investment-rating) {
    .rating-#{$i} {
      background-color: nth($investment-rating, $i);
      text-align: center;
    }
    &.rating-result-#{$i} {
      .investment-rating__result {
        .rating-#{$i} {
          font-size: 3em;
        }
      }
    }
  }

  &__example {
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        flex: 3;
        flex-direction: column;
        list-style: none;
        line-height: 45px;
        padding-left: 10px;
        padding-right: 10px;
        &:first-child {
          flex: 2;
        }
        &:last-child {
          flex: 1;
        }
      }
      &:nth-child(odd) {
        background: white;
      }
    }
    .investment-rating__result-icons {
      margin-top: 2px;
    }
    .table-head {
      background: $brand-secondary;
      color: white;
      position: relative;
      text-transform: uppercase;
      z-index: 0;
      &:nth-child(odd) {
        background: $brand-secondary;
        color: white;
      }
      &:before {
        content: '';
        position: absolute;
        left: -500px;
        top: 0;
        bottom: 0;
        right: 50%;
        display: block;
        background: $brand-secondary;
        z-index: -1;
      }
      li ~ li {
        border-left: 2px solid white;
      }
    }
    @media (max-width: $screen-md) {
      .table-head {
        display: none;
      }
      ul {
        font-size: 15px;
        background: #FFFFFF;
        border-width: 0 0 1px 0;
        border-color: #000000;
        border-style: solid;
        display: block;
        &:nth-child(odd) {
          background: $background;
        }
        &:first-child {
          border-top-width: 2px;
        }
        &:last-child {
          border-bottom-width: 2px;
        }
        li {
          display: block;
          line-height: 30px;
          width: 100%;
          padding: 0 5px;
          padding-left: 50%;
          position: relative;
          text-align: right;
          &:before {
            content: attr(data-line);
            position: absolute;
            left: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }

  &__howwerate {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    &__left,
    &__right{
      display: none;
    }
    @media screen and (max-width: $screen-xs-max){
      height: 75px;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      flex-wrap: unset;
      justify-content: unset;
      &__container{
        position: relative;
      }
      &__left,
      &__right{
        background: linear-gradient(to right, rgba(255,255,255,1), transparent);
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 50px;
      }
      &__right{
        background: linear-gradient(to left, rgba(255,255,255,1), transparent);
        left: auto;
        right: 0;
      }
      &::-webkit-scrollbar{
        display: none;
      }
    }
    &__result-icons {
      background: $background;
      color: white;
      display: inline-block;
      height: 4em;
      line-height: 2em;
      text-transform: uppercase;
      vertical-align: middle;
      width: 2em;
      &:first-child {
        margin-left: 0;
      }
      &:empty {
        display: none;
      }
      @media screen and (max-width: $screen-xs-max) {
        flex: 0 0 45px;
        height: auto;
        width: 45px;
      }
    }
  }

}
