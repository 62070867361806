.network{
  position: relative;
  overflow: hidden;
  &:before{
    content: '';
    background: $light-blue;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 130px;
    left: 0;
    right: 0;
  }
  .teaser {
    background: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: $color-grey-base;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    &:hover {
      background: $brand-secondary;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
      color: white;
      .teaser--headline,
      .teaser--summary,
      .teaser--link .btn--small {
        color: white;
      }
      .teaser--link .btn--small {
        border-color: white;
      }
    }
    a {
      text-decoration: none;
    }
    &--image{
      height: 140px;
      transition: all 0.4s;
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        min-height: 100%;
        height: auto;
        transition: all 0.4s;
      }
    }
    &--container{
      height: 110px;
    }
    &--headline {
      margin: 0;
      font-size: 20px;
      line-height: 26px;
      text-align: left;
      padding-right:0.5em;
      width: 70%;
      @media screen and (max-width: $screen-xs-max) {
        font-size: 5vw;
      }
    }
    &--content{
      height: 100%;
      padding: 15px;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
      display: flex;
    }
    &--summary {
      color: $color-grey-base;
      margin-top: 10px;
      padding-right: 21px;
      max-height: 4.3em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: unset;
      p {
        margin: 0;
      }
    }
    &--link {
      position: absolute;
      bottom: 25px;
    }
    &--logo{
      align-self: flex-end;
      max-width: 100%;
      width: 30%;
      img{
        display: block;
        max-width: 100%;
        transition: all 0.4s;
        object-fit: cover;
      }
    }
  }
}