.quotes{
  padding: 75px 0;
  @media screen and (max-width: $screen-xs-max){
    padding:55px 0;
  }
  &__background-blue{
    background: $light-blue;
  }
  &__background-green{
    background: lighten($bestand, 45%);
  }
  &__background-white{
    background: white;
    .wrap{
      .quotes &{
        padding: 75px 0;
      }
      border-bottom: 3px solid $gray-bright;
      border-top: 3px solid $gray-bright;
    }
  }
  .wrap{
    padding:75px 25px;
    position: relative;
  }
  &__image{
    border: 5px solid white;
    border-radius: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    height: 5em;
    position: absolute;
    top: -2.5em;
    overflow: hidden;
    width: 5em;
    img{
      height:100%;
      width: 100%;
    }
    filter: grayscale(100%);
    transition: all 0.5s ease;
    &:hover {
      filter: none;
    }
  }
  &__text{
    color: #626262;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    line-height: auto;
    font-weight: 400;
    text-transform: none;
    text-align: left;
    max-width: 80%;
    margin: 10px 0 50px;
    p{
      margin:0;
      hyphens: initial;
      font-size: 24px;
    }
  }
  &__signature{
    &__headline{
      @extend .headline;
      display:block;
      color:$brand-secondary;
      font-size:18px;
      font-weight:bold;
      letter-spacing: 1px;
      line-height: 1;
    }
    &__subline{
      display: block;
      font-size: 12px;
      line-height: 1;
    }
  }
}