.accordion {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  overflow: hidden;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  &-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
    p {
      margin: 1em;
    }
  }
  label {
    position: relative;
    display: block;
    font-weight: bold;
    line-height: 3;
    cursor: pointer;
    &::after {
      font-family: fontello;
      font-style: normal;
      font-weight: 400;
      box-shadow: none;
      speak: none;
      text-decoration: inherit;
      text-align: center;
      margin-left: .5em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: none;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      font-size: 0.7em;
      line-height: 1;
      padding: 1.6em 0.5em 0.5em;
      height: 100%;
      -webkit-transition: all .35s;
      -o-transition: all .35s;
      transition: all .35s;
    }
  }
  input{
    &:checked ~ .accordion-content {
      max-height: 100%;
    }
    &[type=checkbox] + label::after,
    &[type=radio] + label::after {
      content: "\e805";
    }
    &[type=checkbox]:checked + label::after {
      transform: rotate(-180deg);
      .dropdown &{
        animation-play-state: paused;
      }
    }
    &[type=radio]:checked + label::after {
      transform: rotateX(180deg);
    }
  }
  &.up{
    label {
      &::before {
        font-family: fontello;
        font-style: normal;
        font-weight: 400;
        box-shadow: none;
        speak: none;
        text-decoration: inherit;
        text-align: center;
        margin-left: .5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: none;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        font-size: 0.7em;
        line-height: 1;
        padding: 1.6em 0.5em 0.5em;
        height: 100%;
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
      }
    }
    input{
      &[type=checkbox] + label::after,
      &[type=radio] + label::after {
        content: "";
      }
      &[type=checkbox] + label::before,
      &[type=radio] + label::before {
        content: "\e805";
      }
      &[type=checkbox]:checked + label::before {
        transform: rotate(-180deg);
        .dropdown &{
          animation-play-state: paused;
        }
      }
      &[type=radio]:checked + label::before {
        transform: rotateX(180deg);
      }
    }
  }
}

.accordion-mobile {
  input {
    display: none;
  }
  @media screen and (max-width: $screen-xs-max){
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    color: $brand-secondary;
    overflow: hidden;
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    &-content {
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height .35s;
      -o-transition: max-height .35s;
      transition: max-height .35s;
    }
    label {
      position: relative;
      display: block;
      font-weight: 600;
      line-height: 3;
      cursor: pointer;
      &::after {
        font-family: fontello;
        font-style: normal;
        font-weight: 400;
        box-shadow: none;
        speak: none;
        text-decoration: inherit;
        text-align: center;
        margin-left: .5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: none;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        font-size: 0.5em;
        line-height: 1;
        padding: 2.5em 2.5em 0;
        height: 100%;
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
      }
    }
    input{
      &:checked ~ .accordion-mobile-content {
        max-height: 100%;
      }
      &[type=checkbox] + label::after,
      &[type=radio] + label::after {
        content: "\e805";
      }
      &[type=checkbox]:checked + label::after {
        transform: rotate(-180deg);
      }
      &[type=radio]:checked + label::after {
        transform: rotateX(180deg);
      }
    }
  }
}
