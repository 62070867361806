.table-block{
  &__headline{
    padding-top: 75px;
    @media screen and (max-width: $screen-xs-max) {
      padding-top: 55px;
    }
  }
  &__subline{
    margin-bottom: 15px;
  }
  &__content{
    margin-top: 15px;
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      flex: 3;
      flex-direction: column;
      list-style: none;
      line-height: 30px;
      padding: 0.5em;
      padding-left: 10px;
      padding-right: 10px;
    }
    &:nth-child(odd) {
      background: white;
    }
  }
  .table-head {
    background: $brand-secondary;
    color: white;
    position: relative;
    text-transform: uppercase;
    z-index: 0;
    &:nth-child(odd) {
      background: $brand-secondary;
      color: white;
    }
    &:before {
      content: '';
      position: absolute;
      left: -500px;
      top: 0;
      bottom: 0;
      right: 50%;
      display: block;
      background: $brand-secondary;
      z-index: -1;
    }
    li ~ li {
      border-left: 2px solid white;
    }
  }
  .table-body{
    background: $background;
  }
  .table-footer{
    border-top: 1px solid $black;
  }
  &.table{
    @media (min-width: $screen-md) {
      .table-head,
      .table-body,
      .table-footer{
        li:first-child:nth-last-child(1) {
          width: 100%;
        }
        li:first-child:nth-last-child(2),
        li:first-child:nth-last-child(2) ~ li {
          width: 50%;
        }
        li:first-child:nth-last-child(3),
        li:first-child:nth-last-child(3) ~ li {
          width: 33.3333%;
        }
        li:first-child:nth-last-child(4),
        li:first-child:nth-last-child(4) ~ li {
          width: 25%;
        }
        li:first-child:nth-last-child(5),
        li:first-child:nth-last-child(5) ~ li {
          width: 20%;
        }
        li:first-child:nth-last-child(6),
        li:first-child:nth-last-child(6) ~ li {
          width: 16.6%;
        }
      }
    }
    @media (max-width: $screen-md) {
      .table-head {
      }
      ul {
        font-size: 15px;
        background: white;
        border-width: 0 0 1px 0;
        border-color: #000000;
        border-style: solid;
        display: block;
        &:nth-child(odd) {
          background: $background;
        }
        &:first-child {
          border-top-width: 2px;
        }
        &:last-child {
          border-bottom-width: 2px;
        }
        li {
          display: block;
          line-height: 30px;
          width: 100%;
          padding: 0 5px;
          padding-left: 50%;
          position: relative;
          text-align: right;
          &:before {
            content: attr(data-line);
            position: absolute;
            left: 5px;
            font-weight: bold;
            max-width: 50%;
            text-align:left;
          }
        }
      }
    }
  }
  &.compare{
    @media (max-width: $screen-md) {
      .table-head {
      }
      ul {
        font-size: 15px;
        background: white;
        border-width: 0 0 1px 0;
        border-color: #000000;
        border-style: solid;
        display: block;
        &:nth-child(odd) {
          background: $background;
        }
        &:first-child {
        border-top-width: 2px;
        }
        &:last-child {
          border-bottom-width: 2px;
        }
        li {
          display: block;
          line-height: 25px;
          width: 100%;
          padding: 0 5px;
          position: relative;
          &:before {
            content: attr(data-line);
            display: block;
            font-weight: bold;
            width: 100%;
            text-align:left;
          }
        }
      }
    }
  }
  &.key-value{
    @media (max-width: $screen-md) {
      .table-head {
        background: unset;
        display: flex;
      }
      ul {
        font-size: 15px;
        background: white;
        border-width: 0 0 1px 0;
        border-color: #000000;
        border-style: solid;
        display: flex;
        &:nth-child(odd) {

        }
        &:first-child {
          border-top-width: 0;
        }
        &:last-child {
          border-bottom-width: 2px;
        }
        li {
          display: block;
          line-height: 25px;
          width: 100%;
          padding: 0 5px;
          text-align: right;
          &:first-child{
            text-align: left;
          }
        }
      }
      .table-head:before {
        left: 0;
      }
    }

  }
  &.bestand{
    .table-head:before,
    .table-head{
      background: $bestand;
    }
    &.compare{
      .table-head{
        display: none;
      }
    }
  }

  &.finanzierung{
    .table-head:before,
    .table-head{
      background: $finanzierung;
    }
    @media (max-width: $screen-md) {
      .table-head{
        display: none;
      }
    }
  }
}
